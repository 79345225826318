import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { ManualTrailStatus } from '../../models/ManualTrailStatus.model'
import { ManualTrailStatusDTO } from '../../models/ManualTrailStatusDTO.model'
import { ManualTrailStatusAddEvent } from '../../events/ManualTrailStatusAddEvent'
import { ManualTrailStatusUpdateEvent } from '../../events/ManualTrailStatusUpdateEvent'
import { ManualTrailStatusSearchEvent } from '../../events/ManualTrailStatusSearchEvent'
import { ManualTrailStatusRelations } from '../../models/ManualTrailStatusRelations.model'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { TrailSystemService } from '../TrailSystemService/TrailSystemService'
import { TrailService } from '../TrailService/TrailService'
import { formatToDateTypeProps, formatToNumberTypeProps } from '../../utils/formatting'

export class ManualTrailStatusService {
  /**
   * @description Search for a single manualTrailStatus by id
   *
   * @param id
   * @param relations
   *
   * @returns promise for a ManualTrailStatus
   */
  public async get({
    id,
    relations,
  }: {
    id?: number
    relations?: ManualTrailStatusRelations[]
  }): Promise<ManualTrailStatus> {
    const params = {
      id,
      relations:
        Array.isArray(relations) && !relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : relations?.join(',') || undefined,
    }
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `manual-trail-status/get`,
      params,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const manualTrailStatusRaw = await flowfeedApiQueryService.get<ManualTrailStatusDTO>(flowfeedApiQueryRequest)
      return ManualTrailStatusService.formatManualTrailStatus(manualTrailStatusRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: ManualTrailStatusSearchEvent): Promise<PaginatedResponse<ManualTrailStatus>> {
    const params = {
      ...event,
      reason: event.reason || undefined,
      orderDirection: event.orderDirection?.toUpperCase(),
      relations:
        Array.isArray(event.relations) && !event.relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : event.relations?.join(',') || undefined,
      systemIds: Array.isArray(event.systemIds) ? event.systemIds.join(',') : undefined,
      trailIds: Array.isArray(event.trailIds) ? event.trailIds.join(',') : undefined,
      isActive: event.isActive !== undefined ? (event.isActive ? 'true' : 'false') : undefined,
      includePast: event.includePast !== undefined ? (event.includePast ? 'true' : 'false') : undefined,
      includeFuture: event.includeFuture !== undefined ? (event.includeFuture ? 'true' : 'false') : undefined,
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'manual-trail-status',
      params,
    }

    const manualTrailStatuses: {
      data: ManualTrailStatus[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawManualTrailStatuses = await flowfeedApiQueryService.get<PaginatedResponse<ManualTrailStatusDTO>>(
        flowfeedApiQueryRequest,
      )
      // wrap to avoid errors if the request is cancelled
      if (rawManualTrailStatuses?.data) {
        manualTrailStatuses.data = rawManualTrailStatuses.data.map((manualTrailStatus) =>
          ManualTrailStatusService.formatManualTrailStatus(manualTrailStatus),
        )
        manualTrailStatuses.total = rawManualTrailStatuses.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return manualTrailStatuses
  }

  public async add(manualTrailStatus: ManualTrailStatusAddEvent): Promise<ManualTrailStatus> {
    const flowfeedApiQueryRequest = {
      endpoint: 'manual-trail-status',
      data: manualTrailStatus,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const manualTrailStatusRaw = await flowfeedApiQueryService.post<ManualTrailStatusDTO>(flowfeedApiQueryRequest)
      return ManualTrailStatusService.formatManualTrailStatus(manualTrailStatusRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, manualTrailStatus: ManualTrailStatusUpdateEvent): Promise<ManualTrailStatus> {
    const flowfeedApiQueryRequest = {
      endpoint: `manual-trail-status/${id}`,
      data: manualTrailStatus,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const manualTrailStatusRaw = await flowfeedApiQueryService.patch<ManualTrailStatusDTO>(flowfeedApiQueryRequest)
      return ManualTrailStatusService.formatManualTrailStatus(manualTrailStatusRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `manual-trail-status/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    manualTrailStatuses: (Partial<ManualTrailStatus> & {
      trailId?: number
      systemId?: number
    })[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = manualTrailStatuses.map((manualTrailStatus) => {
      const numberTypeProps = ['id', 'trailId', 'systemId']
      const dateTypeProps = ['start', 'end']
      const formattedSponsor = formatToDateTypeProps(
        formatToNumberTypeProps(manualTrailStatus, numberTypeProps),
        dateTypeProps,
      )
      return {
        ...formattedSponsor,
        start:
          (formattedSponsor?.start as unknown) === 'null' || (formattedSponsor?.start as unknown) === 'NULL'
            ? null
            : formattedSponsor?.start,
        end:
          (formattedSponsor?.end as unknown) === 'null' || (formattedSponsor?.end as unknown) === 'NULL'
            ? null
            : formattedSponsor?.end,
      }
    })
    const flowfeedApiQueryRequest = {
      endpoint: `manual-trail-status/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public static formatManualTrailStatus(manualTrailStatus: ManualTrailStatusDTO): ManualTrailStatus {
    return {
      id: manualTrailStatus.id,
      reason: manualTrailStatus.reason,
      region: manualTrailStatus.region,
      status: manualTrailStatus.status,
      system: manualTrailStatus.system ? TrailSystemService.formatTrailSystem(manualTrailStatus.system) : undefined,
      trail: manualTrailStatus.trail ? TrailService.formatTrail(manualTrailStatus.trail) : undefined,
      start: manualTrailStatus.start ? new Date(manualTrailStatus.start) : null,
      end: manualTrailStatus.end ? new Date(manualTrailStatus.end) : null,
      updatedAt: new Date(manualTrailStatus.updatedAt),
      createdAt: new Date(manualTrailStatus.createdAt),
    }
  }
}
