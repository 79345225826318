import { useEffect, useMemo, useRef, useState } from 'react'
import * as d3 from 'd3'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import pick from 'lodash/pick'
import { formatDate } from '../../../utils/formatting'

import { AppLayoutContext } from '../../../providers/AppLayout'
import { useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { ConditionalStatusGroup, conditionalStatusGroupFields } from '../../../models/ConditionalStatusGroup.model'
import { Trail } from '../../../models/Trail.model'

import UserService from '../../../services/UserService/UserService'
import { ConditionalStatusGroupService } from '../../../services/ConditionalStatusGroupService/ConditionalStatusGroupService'

import {
  Box,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Paper,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DataTable from '../../../components/DataTable/DataTable.component'
import Loading from '../../../components/Loading/Loading.component'
import ImportExportDialog, {
  ImportExportDialogProps,
} from '../../../components/ImportExportDialog/ImportExportDialog.component'
import Link from '@mui/material/Link'
import ConditionalStatusGroupForm from '../shared/ConditionalStatusGroupForm.component'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import { AppStateContext } from '../../../providers/AppStateContext'

type ConditionalStatusGroupSearchProps = {
  nested?: boolean
  hideHeader?: boolean
  hideActions?: boolean
  disablePadding?: boolean
  excludeColumns?: string[]
  extraRowActions?: {
    label: string
    onClick: (row?: ConditionalStatusGroup) => void
  }[]
}
const ConditionalStatusGroupSearch = (props: ConditionalStatusGroupSearchProps): JSX.Element => {
  const {
    nested,
    hideHeader = false,
    hideActions = false,
    disablePadding = false,
    excludeColumns = [],
    extraRowActions = [],
  } = props

  const [isSaving, setIsSaving] = useState(false)

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editTrailFinder])

  const [searchError, setSearchError] = useState('')
  const [rowActionsAnchorEl, setRowActionsAnchorEl] = useState<null | HTMLElement>(null)
  const isActionsMenuOpen = Boolean(rowActionsAnchorEl)
  const [selectedRow, setSelectedRow] = useState<ConditionalStatusGroup | undefined>()
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const [name, setName] = useUrlStateParams(
    '',
    'name',
    (value) => encodeURIComponent(value.toString()),
    (value) => (value ? decodeURIComponent(value) : ''),
  )
  const nameInputRef = useRef<HTMLInputElement>()
  const [orderBy, setOrderBy] = useState<keyof ConditionalStatusGroup | undefined>('name')
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc' | undefined>('asc')
  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 1),
  )
  const [pageSize, setPageSize] = useUrlStateParams(
    12,
    'page-size',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 12),
  )

  const [isImportExportOpen, setIsImportExportOpen] = useState(false)

  const conditionalStatusGroupService = useMemo(() => new ConditionalStatusGroupService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    if (!nested) {
      AppLayoutContext.setPageName('Conditional Status Groups')
    }
  }, [])

  const queryClient = useQueryClient()
  const { isLoading, data: conditionalStatusGroupsResponse } = useQuery(
    [
      'ConditionalStatusGroupService.search',
      {
        page,
        pageSize,
        name,
        orderBy,
        orderDirection,
        relations: [],
      },
      AppStateContext.getRegion(),
    ],
    () =>
      conditionalStatusGroupService.search({
        page,
        pageSize,
        name,
        orderBy,
        orderDirection,
        relations: [],
      }),
    {
      onError: (err) => {
        setSearchError(
          typeof err === 'string' ? err : 'An error occurred while searching for conditional status groups.',
        )
        console.error(err)
      },
    },
  )

  useEffect(() => {
    if (
      conditionalStatusGroupsResponse &&
      page > 1 &&
      (!conditionalStatusGroupsResponse.total || (page - 1) * pageSize >= conditionalStatusGroupsResponse.total)
    ) {
      // page out of range was requested so reset to page 1
      setPage(1)
    }
  }, [conditionalStatusGroupsResponse])

  const handleRowActionsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    conditionalStatusGroup: ConditionalStatusGroup,
  ) => {
    setRowActionsAnchorEl(event.currentTarget)
    setSelectedRow(conditionalStatusGroup)
  }
  const handleRowActionsClose = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
  }

  const columns = useMemo(() => {
    const columns: GridColDef<ConditionalStatusGroup>[] = [
      {
        field: 'actions',
        headerName: '',
        width: 60,
        sortable: false,
        renderCell: (params) => (
          <>
            <IconButton
              id={`row-actions-button-${params.row.id}`}
              color="primary"
              aria-label="row actions"
              aria-controls={selectedRow?.id === params.row.id ? 'row-actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={selectedRow?.id === params.row.id ? 'true' : undefined}
              onClick={(event) => handleRowActionsClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        renderCell: (params) => (
          <Link
            color="primary"
            aria-label="conditional status group detail"
            href={`/conditional-status-groups/${params.row.id}`}
          >
            {params.row.id}
          </Link>
        ),
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 400,
      },
      {
        field: 'updatedAt',
        headerName: 'Updated',
        width: 200,
        renderCell: (params) => formatDate(params.row.updatedAt),
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        width: 200,
        renderCell: (params) => formatDate(params.row.createdAt),
      },
    ]
    // remove any columns specified to be removed in the excludeColumns prop
    if (excludeColumns) {
      return columns.filter((column) => !excludeColumns.includes(column.field as string))
    }
    return columns
  }, [excludeColumns, hasWritePermissions])

  const handleSubmit = () => {
    setPage(1)
    setName(nameInputRef?.current?.value || '')
  }

  const handleAddClick = () => {
    setIsAdding(true)
  }

  const handleAddCancel = () => {
    setIsAdding(false)
  }

  const handleAddSubmit = () => {
    setIsAdding(false)
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleEditCancel = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleEditSubmit = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false)
    handleRowActionsClose()
  }

  const deleteConditionalStatusGroup = useMutation(
    (mutationParams: { id: number }) => {
      setIsSaving(true)
      return conditionalStatusGroupService.delete(mutationParams.id)
    },
    {
      onSuccess: () => {
        SnackbarContext.show('Conditional Status Group deleted successfully!')
        setRowActionsAnchorEl(null)
        setSelectedRow(undefined)
        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSaving(false)
      },
      onError: (err) => {
        SnackbarContext.show(`Conditional Status Group failed to delete: ${err}`, 'error')
        console.error(err)
        setIsSaving(false)
      },
    },
  )

  const handleDelete = () => {
    if (selectedRow?.id) {
      setIsDeleteConfirmationOpen(false)
      deleteConditionalStatusGroup.mutate({ id: selectedRow.id })
    }
  }

  const openImportExport = () => {
    setIsImportExportOpen(true)
  }

  const handleImportExportClose = () => {
    setIsImportExportOpen(false)
  }

  const handleImport: ImportExportDialogProps['onImport'] = (data: Partial<Trail>[], options) => {
    setIsImportExportOpen(false)
    if (!data) {
      SnackbarContext.show('Failed to read/parse file', 'error')
      console.error('Failed to read/parse file')
    } else {
      setIsSaving(true)
      conditionalStatusGroupService
        .import(data, options)
        .then(() => {
          SnackbarContext.show('Conditional Status Groups imported successfully!')

          // fetch new data
          setSearchError('')
          // invalidate all queries to ensure any queries that are using the modified record are updated
          queryClient.invalidateQueries()
          setIsSaving(false)
        })
        .catch((err) => {
          SnackbarContext.show(`Conditional Status Groups failed to import: ${err}`, 'error')
          console.error(err)
          setIsSaving(false)
        })
    }
  }

  const handleExport = () => {
    // do a request to the API to get the data to export with no pagination
    setIsSaving(true)
    setIsImportExportOpen(false)
    conditionalStatusGroupService
      .search({
        pageSize: 0,
        name,
        orderBy,
        orderDirection,
        relations: [],
      })
      .then((results) => {
        const exportData = results.data.map((conditionalStatusGroup) => {
          const conditionalStatusGroupToExport: Record<string, unknown> = { ...conditionalStatusGroup }
          delete conditionalStatusGroupToExport.trails
          delete conditionalStatusGroupToExport.conditionalStatuses

          conditionalStatusGroupToExport.updatedAt =
            conditionalStatusGroupToExport.updatedAt instanceof Date
              ? conditionalStatusGroupToExport.updatedAt.toISOString()
              : ''
          conditionalStatusGroupToExport.createdAt =
            conditionalStatusGroupToExport.createdAt instanceof Date
              ? conditionalStatusGroupToExport.createdAt.toISOString()
              : ''

          // return the object with the props in the order we want them to appear in the csv
          const propsToExport: string[] = [...conditionalStatusGroupFields]
          return pick(conditionalStatusGroupToExport, propsToExport)
        })
        const csvData = d3.csvFormat(exportData)
        // initiate a download of the csv file
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.setAttribute('href', url)
        link.setAttribute('download', 'conditional-status-groups.csv')
        link.click()
        setIsSaving(false)
      })
      .catch((err) => {
        SnackbarContext.show(`Failed to fetch conditional status groups for export: ${err}`, 'error')
        console.error(err)
      })
  }

  return (
    <>
      {selectedRow && (
        <FormDialog<ConditionalStatusGroup>
          open={isEditing}
          entity={selectedRow}
          entityLabel="Conditional Status Group"
          onClose={handleEditCancel}
        >
          <ConditionalStatusGroupForm
            conditionalStatusGroup={selectedRow}
            onEdit={handleEditSubmit}
            onCancel={handleEditCancel}
          />
        </FormDialog>
      )}
      <FormDialog<ConditionalStatusGroup>
        open={isAdding}
        entityLabel="Conditional Status Group"
        onClose={handleAddCancel}
      >
        <ConditionalStatusGroupForm onAdd={handleAddSubmit} onCancel={handleAddCancel} />
      </FormDialog>
      <Dialog fullWidth maxWidth="xs" open={isDeleteConfirmationOpen} onClose={handleDeleteConfirmationClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete conditional status group id {selectedRow?.id}: {selectedRow?.name}?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteConfirmationClose}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <ImportExportDialog
        open={isImportExportOpen}
        disableImport={!hasWritePermissions}
        exampleImportFileUrl="/example-import-files/conditional-status-groups.csv"
        importFields={[
          ...conditionalStatusGroupFields.filter((field) => !['answers', 'updatedAt', 'createdAt'].includes(field)),
        ]}
        onImport={handleImport}
        onExport={handleExport}
        onClose={handleImportExportClose}
      />
      <Paper sx={{ p: disablePadding ? 0 : 2 }}>
        {!hideHeader && (
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            Conditional Status Groups
          </Typography>
        )}
        {(isLoading || isSaving) && !searchError && <Loading sx={{ py: 20 }} />}
        {searchError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{searchError}</Typography>
          </Stack>
        )}
        {!isLoading && !isSaving && !searchError && (
          <>
            {!hideActions && (
              <Box sx={{ pt: 1, mb: 1 }}>
                <Stack direction={{ zero: 'column', md: 'row' }} spacing={1}>
                  <FormControl variant="outlined" sx={{ width: '100%' }}>
                    <InputLabel>Name</InputLabel>
                    <OutlinedInput
                      id="search-name"
                      label="Name"
                      defaultValue={name || ''}
                      inputRef={nameInputRef}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSubmit()
                        }
                      }}
                      endAdornment={
                        nameInputRef?.current?.value ? (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear search name"
                              onClick={() => {
                                if (nameInputRef?.current?.value) {
                                  nameInputRef.current.value = ''
                                }
                                setPage(1)
                                setName('')
                              }}
                              edge="end"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : undefined
                      }
                    />
                  </FormControl>

                  <Button
                    variant="outlined"
                    onClick={handleSubmit}
                    sx={(theme) => ({ [theme.breakpoints.up('md')]: { width: 150 }, maxWidth: '100%' })}
                  >
                    Search
                  </Button>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {hasWritePermissions && (
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        color="primary"
                        aria-label="add new"
                        onClick={handleAddClick}
                      >
                        Add
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      startIcon={<ImportExportIcon />}
                      color="primary"
                      aria-label="show import export"
                      onClick={() => {
                        openImportExport()
                      }}
                    >
                      Import / Export
                    </Button>
                  </Stack>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      if (nameInputRef?.current?.value) {
                        nameInputRef.current.value = ''
                      }
                      setPage(1)
                      setName('')
                    }}
                  >
                    Clear
                  </Button>
                </Stack>
              </Box>
            )}

            {conditionalStatusGroupsResponse?.data && conditionalStatusGroupsResponse.data.length > 0 ? (
              <>
                <Menu
                  id="row-actions-menu"
                  anchorEl={rowActionsAnchorEl}
                  open={isActionsMenuOpen}
                  onClose={handleRowActionsClose}
                  MenuListProps={{
                    'aria-labelledby': `row-actions-button${selectedRow?.id}`,
                  }}
                >
                  <MenuItem href={`/conditional-status-groups/${selectedRow?.id}`} component={Link}>
                    View
                  </MenuItem>
                  {!hideActions && hasWritePermissions && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}
                  {!hideActions && hasWritePermissions && <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>}
                  {Array.isArray(extraRowActions) && extraRowActions.length > 0 && <Divider sx={{ my: 0.5 }} />}
                  {Array.isArray(extraRowActions) &&
                    extraRowActions.length > 0 &&
                    extraRowActions.map((action, index) => (
                      <MenuItem key={index} onClick={() => action.onClick(selectedRow)}>
                        {action.label}
                      </MenuItem>
                    ))}
                </Menu>
                <DataTable
                  columns={columns}
                  data={conditionalStatusGroupsResponse.data}
                  total={conditionalStatusGroupsResponse.total}
                  paginationMode="server"
                  page={page}
                  pageSize={pageSize}
                  onPageChange={(newPage) => {
                    setPage(newPage)
                  }}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  onOrderChange={(newOrder) => {
                    setOrderBy(newOrder.field ? (newOrder.field as keyof ConditionalStatusGroup) : undefined)
                    setOrderDirection(newOrder.sort)
                  }}
                />
              </>
            ) : (
              <Box sx={{ p: 2 }}>
                <Typography>No results found</Typography>
              </Box>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default ConditionalStatusGroupSearch
