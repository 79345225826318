import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { AppLayoutContext } from '../../../providers/AppLayout'
import { useAppNavigate, useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'
import { formatDate } from '../../../utils/formatting'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { TrailFinderAnswer } from '../../../models/TrailFinderAnswer.model'
import { TrailFinderAnswerRelations } from '../../../models/TrailFinderAnswerRelations.model'

import UserService from '../../../services/UserService/UserService'
import { TrailFinderAnswerService } from '../../../services/TrailFinderAnswerService/TrailFinderAnswerService'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs.component'
import TrailFinderAnswerForm from '../shared/TrailFinderAnswerForm.component'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import { TrailFeatureService } from '../../../services/TrailFeatureService/TrailFeatureService'
import { TrailService } from '../../../services/TrailService/TrailService'
import { Difficulties } from '../../../models/Difficulties.model'
import { trailStatuses } from '../../../models/TrailStatuses.model'
import { AppStateContext } from '../../../providers/AppStateContext'

const TrailFinderAnswerDetail = (): JSX.Element => {
  const navigate = useAppNavigate()
  const { id } = useParams<{ id: string }>()
  const [loadingError, setLoadingError] = useState('')

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editTrailFinder])

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useUrlStateParams<boolean>(
    false,
    'edit',
    (value) => (value ? 't' : ''),
    (value) => value === 't',
  )
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const trailFinderAnswerService = useMemo(() => new TrailFinderAnswerService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    AppLayoutContext.setPageName(`Trail Finder Answer Detail`)
  }, [id])

  const queryClient = useQueryClient()
  const { isLoading: isLoadingTrailFinderAnswer, data: trailFinderAnswer } = useQuery(
    [
      'TrailFinderAnswerService.get',
      {
        id: Number(id),
        relations: [TrailFinderAnswerRelations.question],
      },
      AppStateContext.getRegion(),
    ],
    () =>
      trailFinderAnswerService.get({
        id: Number(id),
        relations: [TrailFinderAnswerRelations.question],
      }),
    {
      onError: (err) => {
        setLoadingError(typeof err === 'string' ? err : 'Failed to load trail finder answer.')
        console.error(err)
      },
    },
  )

  const trailService = useMemo(() => new TrailService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingSurfaceTypes, data: surfaceTypes } = useQuery(
    ['TrailService.listSurfaceTypes', AppStateContext.getRegion()],
    () => trailService.listSurfaceTypes(),
    {
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch surface types for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )

  const trailFeatureService = useMemo(() => new TrailFeatureService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingTrailFeatures, data: trailFeaturesResponse } = useQuery(
    ['TrailFeatureService.search', { pageSize: 0 }, AppStateContext.getRegion()],
    () => trailFeatureService.search({ pageSize: 0 }),
    {
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch trail features for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )
  // consolidate the various loading states into one
  useEffect(() => {
    setIsLoading(!isLoadingTrailFinderAnswer && !isLoadingTrailFeatures && !isLoadingSurfaceTypes ? false : true)
  }, [isLoadingTrailFinderAnswer, isLoadingTrailFeatures, isLoadingSurfaceTypes])

  const handleEdit = () => {
    setIsEditing(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDelete = () => {
    if (trailFinderAnswer?.id) {
      setIsSaving(true)
      setIsDeleteConfirmationOpen(false)
      trailFinderAnswerService
        .delete(trailFinderAnswer.id)
        .then(() => {
          SnackbarContext.show(`Trail Finder Answer deleted successfully. You will now be redirected.`)

          // after a delay of 1 second, redirect to the trailFinderAnswer list page
          setTimeout(() => {
            navigate(`/trail-finder-questions/${trailFinderAnswer?.question?.id}`)
            queryClient.removeQueries([
              'TrailFinderAnswerService.get',
              {
                id: Number(id),
                relations: [TrailFinderAnswerRelations.question],
              },
            ])
            // invalidate all queries to ensure any queries that are using the modified record are updated
            queryClient.invalidateQueries()
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
          SnackbarContext.show(`Trail Finder Answer failed to delete: ${err}`, 'error')
          setIsSaving(false)
        })
    }
  }

  return (
    <>
      {trailFinderAnswer && (
        <FormDialog<TrailFinderAnswer>
          open={isEditing}
          entity={trailFinderAnswer}
          entityLabel="Trail Finder Answer"
          onClose={() => setIsEditing(false)}
        >
          <TrailFinderAnswerForm
            trailFinderAnswer={trailFinderAnswer}
            onEdit={handleEdit}
            onCancel={() => setIsEditing(false)}
          />
        </FormDialog>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this trail finder answer?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setIsDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ p: 2 }}>
        {((isLoading && !loadingError) || isLoadingSurfaceTypes || isLoadingTrailFeatures) && (
          <Loading sx={{ py: 20 }} />
        )}
        {loadingError && !isLoadingSurfaceTypes && !isLoadingTrailFeatures && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{loadingError}</Typography>
          </Stack>
        )}
        {!isLoading && !loadingError && !isLoadingSurfaceTypes && !isLoadingTrailFeatures && trailFinderAnswer && (
          <>
            {isSaving && <Loading sx={{ py: 20 }} />}
            {!isSaving && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                  <Breadcrumbs
                    items={() => {
                      const breadcrumbs: Array<{ label: string; href?: string }> = [
                        {
                          label: 'Trail Finder',
                          href: '/trail-finder-questions',
                        },
                      ]
                      if (trailFinderAnswer.question?.id) {
                        breadcrumbs.push({
                          label: `Question ID: ${trailFinderAnswer.question.id}`,
                          href: `/trail-finder-questions/${trailFinderAnswer.question.id}`,
                        })
                      }
                      breadcrumbs.push({ label: `Answer ID: ${trailFinderAnswer.id}` })

                      return breadcrumbs
                    }}
                  />
                  {hasWritePermissions && (
                    <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                      <Button variant="text" onClick={handleDeleteClick}>
                        Delete
                      </Button>
                      <Button variant="contained" onClick={() => setIsEditing(true)}>
                        Edit
                      </Button>
                    </Stack>
                  )}
                </Stack>
                <Box>
                  <Box>
                    <Typography variant="h6" component="h3">
                      {trailFinderAnswer.answer}
                    </Typography>
                    <Typography variant="subtitle1" color={(theme) => theme.palette.grey[500]}>
                      ID: {trailFinderAnswer.id}
                    </Typography>
                    {!trailFinderAnswer.active && (
                      <Typography variant="subtitle1" color={(theme) => theme.palette.error.main}>
                        Inactive
                      </Typography>
                    )}
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Affected Filters:</Typography>
                    {!trailFinderAnswer.affectedFilters ? (
                      <Typography variant="body1">None</Typography>
                    ) : (
                      <>
                        {Array.isArray(trailFinderAnswer.affectedFilters.difficulties) && (
                          <Box>
                            <Typography variant="subtitle2">Difficulties:</Typography>
                            <Box component="ul">
                              {trailFinderAnswer.affectedFilters?.difficulties?.map((difficulty) => (
                                <Typography key={difficulty} component="li" variant="subtitle2">
                                  {
                                    Object.entries(Difficulties).find(
                                      (eachDifficulty) => eachDifficulty[1] === difficulty,
                                    )?.[0]
                                  }
                                </Typography>
                              ))}
                            </Box>
                          </Box>
                        )}
                        {Array.isArray(trailFinderAnswer.affectedFilters.radius) && (
                          <Box>
                            <Typography variant="subtitle2">Radius:</Typography>
                            <Box component="ul">
                              {trailFinderAnswer.affectedFilters?.radius?.map((radius) => (
                                <Typography key={radius} component="li" variant="subtitle2">
                                  {radius} Miles
                                </Typography>
                              ))}
                            </Box>
                          </Box>
                        )}
                        {Array.isArray(trailFinderAnswer.affectedFilters.features) && (
                          <Box>
                            <Typography variant="subtitle2">Trail Feature:</Typography>
                            <Box component="ul">
                              {trailFinderAnswer.affectedFilters?.features?.map((trailFeatureId) => (
                                <Typography key={trailFeatureId} component="li" variant="subtitle2">
                                  {trailFeaturesResponse?.data.find((feature) => feature.id === trailFeatureId)?.name}
                                </Typography>
                              ))}
                            </Box>
                          </Box>
                        )}
                        {Array.isArray(trailFinderAnswer.affectedFilters.statuses) && (
                          <Box>
                            <Typography variant="subtitle2">Statuses:</Typography>
                            <Box component="ul">
                              {trailFinderAnswer.affectedFilters?.statuses?.map((status) => (
                                <Typography key={status} component="li" variant="subtitle2">
                                  {trailStatuses.find((trailStatus) => trailStatus.id === status)?.name}
                                </Typography>
                              ))}
                            </Box>
                          </Box>
                        )}
                        {Array.isArray(trailFinderAnswer.affectedFilters.surfaceTypes) && (
                          <Box>
                            <Typography variant="subtitle2">Surface Type:</Typography>
                            <Box component="ul">
                              {trailFinderAnswer.affectedFilters?.surfaceTypes?.map((surfaceType) => (
                                <Typography key={surfaceType} component="li" variant="subtitle2">
                                  {surfaceTypes?.find((type) => type === surfaceType)}
                                </Typography>
                              ))}
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Sort Weight: {trailFinderAnswer.sortWeight}</Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Updated At: {formatDate(trailFinderAnswer.updatedAt)}</Typography>
                    <Typography variant="body1">Created At: {formatDate(trailFinderAnswer.createdAt)}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default TrailFinderAnswerDetail
