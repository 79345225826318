import { useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { ConditionalStatus } from '../../../models/ConditionalStatus.model'
import { ConditionalStatusAddEvent } from '../../../events/ConditionalStatusAddEvent'
import { ConditionalStatusUpdateEvent } from '../../../events/ConditionalStatusUpdateEvent'
import { AppPermissions } from '../../../models/AppPermissions.model'
import { ConditionalStatusGroup } from '../../../models/ConditionalStatusGroup.model'

import UserService from '../../../services/UserService/UserService'
import { ConditionalStatusGroupService } from '../../../services/ConditionalStatusGroupService/ConditionalStatusGroupService'
import { ConditionalStatusService } from '../../../services/ConditionalStatusService/ConditionalStatusService'

import { Stack, FormControl, InputLabel, Button, Select, MenuItem, Typography, Box } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import { AppStateContext } from '../../../providers/AppStateContext'
import { useAppNavigate } from '../../../utils/url'
import { IntRange } from '../../../utils/typing'

export type ConditionalStatusFormProps = {
  conditionalStatus?: ConditionalStatus
  conditionalStatusGroup?: ConditionalStatusGroup
  onAdd?: (conditionalStatus: ConditionalStatus) => void
  onEdit?: (conditionalStatus: ConditionalStatus) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
  disableRedirect?: boolean
}
const ConditionalStatusForm = (props: ConditionalStatusFormProps) => {
  const {
    conditionalStatus,
    conditionalStatusGroup,
    onCancel,
    onAdd,
    onEdit,
    saveTrigger,
    disableRedirect = false,
  } = props

  const navigate = useAppNavigate()

  const { user } = useAuth0()
  const hasViewConditionalStatusGroupPermissions = UserService.hasPermissions(user, [
    AppPermissions.viewConditionalStatus,
  ])

  const [tempConditionalStatus, setTempConditionalStatus] = useState<
    Partial<ConditionalStatus> & {
      conditionalStatusGroupId?: number
    }
  >(
    {
      ...conditionalStatus,
      conditionalStatusGroupId: conditionalStatusGroup
        ? conditionalStatusGroup.id
        : conditionalStatus?.conditionalStatusGroup?.id,
    } || {},
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const queryClient = useQueryClient()

  const conditionalStatusGroupService = useMemo(() => new ConditionalStatusGroupService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingConditionalStatusGroups, data: conditionalStatusGroupsResponse } = useQuery(
    ['ConditionalStatusGroupService.search', { pageSize: 0 }, AppStateContext.getRegion()],
    () => conditionalStatusGroupService.search({ pageSize: 0 }),
    {
      enabled: hasViewConditionalStatusGroupPermissions,
      onError: (err) => {
        SnackbarContext.show(`Failed to fetch conditional status groups for the form: ${err}`, 'error')
        console.error(err)
      },
    },
  )

  useEffect(() => {
    setIsLoading(true)
    if (!hasViewConditionalStatusGroupPermissions) {
      setIsLoading(false)
    } else if (!isLoadingConditionalStatusGroups) {
      setIsLoading(false)
    }
  }, [
    conditionalStatus,
    conditionalStatusGroup,
    isLoadingConditionalStatusGroups,
    hasViewConditionalStatusGroupPermissions,
  ])

  const conditionalStatusService = useMemo(() => new ConditionalStatusService(), []) // memo to prevent re-creating service on every render

  const addConditionalStatus = useMutation(
    (mutationParams: { conditionalStatus: ConditionalStatusAddEvent }) => {
      setIsSubmitting(true)
      return conditionalStatusService.add(mutationParams.conditionalStatus)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Conditional Status added successfully!')

        if (!disableRedirect) {
          if (tempConditionalStatus.conditionalStatusGroupId) {
            // redirect to or refresh the conditional status group
            navigate(`/conditional-status-groups/${tempConditionalStatus.conditionalStatusGroupId}`)
          } else {
            // redirect to the new conditional status
            navigate(`/conditional-statuses/${result.id}`)
          }
          // remove the search query to prevent auto refresh of the search results in case we are on that page and redirecting
          queryClient.removeQueries(['ConditionalStatusService.search'])
        }
        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Conditional Status failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateConditionalStatus = useMutation(
    (mutationParams: { id: number; changedProperties: ConditionalStatusUpdateEvent }) => {
      setIsSubmitting(true)
      return conditionalStatusService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Conditional Status updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Conditional Status failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!conditionalStatusGroup && !tempConditionalStatus.conditionalStatusGroupId) {
      SnackbarContext.show(`Conditional Status conditional status group is required`, 'error')
      return
    }

    if (!conditionalStatus) {
      // format the data to match the API
      const formattedConditionalStatus: ConditionalStatusAddEvent = {
        conditionalStatusGroupId: Number(tempConditionalStatus.conditionalStatusGroupId),
        precipMin: tempConditionalStatus.precipMin || undefined,
        precipMax: tempConditionalStatus.precipMax || undefined,
        lookbackHoursMin: tempConditionalStatus.lookbackHoursMin || undefined,
        lookbackHoursMax: tempConditionalStatus.lookbackHoursMax || undefined,
        status: tempConditionalStatus.status || 0,
        sortWeight: tempConditionalStatus.sortWeight || undefined,
      }

      // adding a new conditionalStatus
      addConditionalStatus.mutate({ conditionalStatus: formattedConditionalStatus })
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof ConditionalStatus) => {
        return tempConditionalStatus[field] !== undefined && tempConditionalStatus[field] !== conditionalStatus[field]
      }

      const changedProperties: ConditionalStatusUpdateEvent = {
        id: conditionalStatus.id,
        precipMin: didChange('precipMin') ? tempConditionalStatus.precipMin : undefined,
        precipMax: didChange('precipMax') ? tempConditionalStatus.precipMax : undefined,
        lookbackHoursMin: didChange('lookbackHoursMin') ? tempConditionalStatus.lookbackHoursMin : undefined,
        lookbackHoursMax: didChange('lookbackHoursMax') ? tempConditionalStatus.lookbackHoursMax : undefined,
        status: didChange('status') ? tempConditionalStatus.status : undefined,
        sortWeight: didChange('sortWeight') ? tempConditionalStatus.sortWeight : undefined,
      }

      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        // edit an existing conditionalStatus
        updateConditionalStatus.mutate({ id: conditionalStatus.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {(isLoading || isSubmitting) && <Loading sx={{ py: 20 }} />}
      {!isLoading && !isSubmitting && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            {(conditionalStatus || conditionalStatusGroup) && (
              <Box>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Conditional Status Group
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  {conditionalStatusGroup
                    ? conditionalStatusGroup.name
                    : conditionalStatus?.conditionalStatusGroup?.name}
                </Typography>
              </Box>
            )}
            {!conditionalStatus &&
              hasViewConditionalStatusGroupPermissions &&
              conditionalStatusGroupsResponse?.data &&
              !conditionalStatusGroup && (
                <>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="conditional-status-conditional-status-group-select-label">
                      Conditional Status Group
                    </InputLabel>
                    <Select
                      labelId="conditional-status-conditional-status-group-select-label"
                      id="conditional-status-conditional-status-group-select"
                      value={tempConditionalStatus.conditionalStatusGroupId || ''}
                      label="Conditional Status Group"
                      onChange={(e) => {
                        setTempConditionalStatus((prev) => ({
                          ...prev,
                          conditionalStatusGroupId: Number(e.target.value),
                        }))
                      }}
                    >
                      <MenuItem value="">-Select-</MenuItem>
                      {conditionalStatusGroupsResponse.data.map((eachConditionalStatusGroup) => {
                        return (
                          <MenuItem key={eachConditionalStatusGroup.id} value={eachConditionalStatusGroup.id}>
                            {eachConditionalStatusGroup.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </>
              )}

            <FormControl variant="outlined" fullWidth>
              <InputLabel id="conditional-status-status-select-label">Status</InputLabel>
              <Select
                labelId="conditional-status-status-select-label"
                id="conditional-status-status-select"
                value={tempConditionalStatus.status || '0'}
                label="Status"
                onChange={(e) => {
                  const value = Number(e.target.value)
                  setTempConditionalStatus((prev) => ({
                    ...prev,
                    status: value >= 0 && value <= 3 ? (value as IntRange<0, 4>) : undefined,
                  }))
                }}
              >
                {/* Have to manually output these since the enum has numeric values which causes the js output to act funky */}
                <MenuItem value="0">Closed</MenuItem>
                <MenuItem value="1">Rutty</MenuItem>
                <MenuItem value="2">Fair</MenuItem>
                <MenuItem value="3">Firm</MenuItem>
              </Select>
            </FormControl>

            <DetailInput<ConditionalStatus>
              label="Precip Min"
              field="precipMin"
              type="number"
              tempDetail={tempConditionalStatus}
              setTempDetail={setTempConditionalStatus}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<ConditionalStatus>
              label="Precip Max"
              field="precipMax"
              type="number"
              tempDetail={tempConditionalStatus}
              setTempDetail={setTempConditionalStatus}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<ConditionalStatus>
              label="Lookback Hrs Min"
              field="lookbackHoursMin"
              type="number"
              tempDetail={tempConditionalStatus}
              setTempDetail={setTempConditionalStatus}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<ConditionalStatus>
              label="Lookback Hrs Max"
              field="lookbackHoursMax"
              type="number"
              tempDetail={tempConditionalStatus}
              setTempDetail={setTempConditionalStatus}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<ConditionalStatus>
              label="Sort Weight"
              helperText="Lower numbers are matched before higher numbers"
              field="sortWeight"
              type="number"
              tempDetail={tempConditionalStatus}
              setTempDetail={setTempConditionalStatus}
              keyDownSubmit={keyDownSubmit}
            />
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default ConditionalStatusForm
