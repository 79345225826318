import { IntRange } from '../utils/typing'
import { AppRegions } from './AppRegions.model'
import { ConditionalStatusGroup } from './ConditionalStatusGroup.model'
import { Difficulties } from './Difficulties.model'
import { Keyword } from './Keyword.model'
import { ManualTrailStatus } from './ManualTrailStatus.model'
import { Sponsor } from './Sponsor.model'
import { TrailFeature } from './TrailFeature.model'
import { TrailStatus } from './TrailStatus.model'
import { TrailSystem } from './TrailSystem.model'
import { USStates } from './USStates.model'
import { WeatherGroup } from './WeatherGroup.model'

export type Trail = {
  id: number
  region: AppRegions
  name: string
  system?: TrailSystem
  weatherGroup?: WeatherGroup
  displayWeatherGroup?: WeatherGroup
  manualTrailStatuses?: ManualTrailStatus[]
  conditionalStatusGroups?: ConditionalStatusGroup[]
  sponsor?: Sponsor
  features?: TrailFeature[]
  status?: TrailStatus
  difficultyId: Difficulties
  description: string
  featuredImgUrl: string
  surfaceType: string
  distance: number
  descent: number
  climb: number
  gpsLat: number
  gpsLong: number
  parkingGpsLat: number
  parkingGpsLong: number
  street: string
  city: string
  state: USStates
  weatherResistance: IntRange<1, 13>
  saturationModifier: number
  freezeModifier: number
  thawModifier: number
  tfTrailUrl: string
  sortWeight: number
  permalink: string
  keywords?: Keyword[]
  active: boolean
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the Trail type's fields in js/ts files
export const trailFields: (keyof Trail)[] = [
  'id',
  'region',
  'name',
  'system',
  'weatherGroup',
  'displayWeatherGroup',
  'manualTrailStatuses',
  'conditionalStatusGroups',
  'features',
  'difficultyId',
  'description',
  'featuredImgUrl',
  'surfaceType',
  'distance',
  'descent',
  'climb',
  'gpsLat',
  'gpsLong',
  'parkingGpsLat',
  'parkingGpsLong',
  'street',
  'city',
  'state',
  'weatherResistance',
  'saturationModifier',
  'freezeModifier',
  'thawModifier',
  'tfTrailUrl',
  'sortWeight',
  'permalink',
  'keywords',
  'active',
  'updatedAt',
  'createdAt',
]
