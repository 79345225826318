import { useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { AppLayoutContext } from '../../providers/AppLayout'

import { AppPermissions } from '../../models/AppPermissions.model'

import UserService from '../../services/UserService/UserService'
import { ConfigService } from '../../services/ConfigService/ConfigService'

import {
  Stack,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
} from '@mui/material'
import Loading from '../../components/Loading/Loading.component'
import { AppStateContext } from '../../providers/AppStateContext'
import { SnackbarContext } from '../../providers/SnackbarContext'

const ConfigAdmin = (): JSX.Element => {
  const [tempConfig, setTempConfig] = useState<Record<string, string>>({})

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [listError, setListError] = useState('')

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editConfig])

  useEffect(() => {
    AppLayoutContext.setPageName('Config')
  }, [])

  const queryClient = useQueryClient()

  const configService = useMemo(() => new ConfigService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingConfigs, data: configsResponse } = useQuery(
    ['ConfigService.get', AppStateContext.getRegion()],
    () => configService.get(),
    {
      onError: (err) => {
        setListError(typeof err === 'string' ? err : 'An error occurred while getting config.')
        console.error(err)
      },
    },
  )

  useEffect(() => {
    if (configsResponse) {
      setTempConfig(configsResponse)
    }
  }, [configsResponse])

  const saveConfigMutation = useMutation(
    (mutationParams: { payload: Record<string, string | null> }) => {
      setIsSubmitting(true)
      return configService.save(mutationParams.payload)
    },
    {
      onSuccess: () => {
        SnackbarContext.show('Config saved successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)
      },
      onError: (err) => {
        SnackbarContext.show(`Config failed to save: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    saveConfigMutation.mutate({ payload: tempConfig })
  }

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          Config
        </Typography>
        {(isLoadingConfigs || isSubmitting) && !listError && <Loading sx={{ py: 20 }} />}
        {listError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{listError}</Typography>
          </Stack>
        )}
        {!isLoadingConfigs &&
          !isSubmitting &&
          !listError &&
          (!hasWritePermissions ? (
            Object.entries(tempConfig).map(([key, value]) => (
              <Typography variant="body1" component="p" sx={{ mb: 2 }}>
                {key}: {value}
              </Typography>
            ))
          ) : (
            <>
              <Stack spacing={2} sx={{ my: 2 }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="config-ftenabled-select-label">Freeze / Thaw Algorithm Enabled</InputLabel>
                  <Select
                    labelId="config-ftenabled-select-label"
                    id="config-ftenabled-select"
                    value={tempConfig.ftEnabled === undefined ? 'auto' : tempConfig.ftEnabled}
                    label="Active"
                    onChange={(e) => {
                      setTempConfig((prev) => ({
                        ...prev,
                        ftEnabled: e.target.value,
                      }))
                    }}
                  >
                    <MenuItem value="auto">Auto</MenuItem>
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                  <FormHelperText>
                    If set to "Auto", the Freeze / Thaw algorithm will activate automatically between December 1st and
                    March 31st
                  </FormHelperText>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="config-ftforced-select-label">Freeze / Thaw Forced</InputLabel>
                  <Select
                    labelId="config-ftforced-select-label"
                    id="config-ftforced-select"
                    value={tempConfig.ftForced === undefined ? 'auto' : tempConfig.ftForced}
                    label="Active"
                    onChange={(e) => {
                      setTempConfig((prev) => ({
                        ...prev,
                        ftForced: e.target.value,
                      }))
                    }}
                  >
                    <MenuItem value="auto">Auto</MenuItem>
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                  <FormHelperText>
                    If set to "Auto", Freeze / Thaw will use the algorithm based on the above Freeze / Thaw Enabled
                    value. If set to "True", Freeze / Thaw will always be active on trails with weather resistance 7 and
                    below. If set to "False", Freeze / Thaw will never be active.
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </Stack>
            </>
          ))}
      </Paper>
    </>
  )
}
export default ConfigAdmin
