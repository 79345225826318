import { formatToNumberTypeProps } from '../../utils/formatting'

import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { ConditionalStatus } from '../../models/ConditionalStatus.model'
import { ConditionalStatusDTO } from '../../models/ConditionalStatusDTO.model'
import { ConditionalStatusAddEvent } from '../../events/ConditionalStatusAddEvent'
import { ConditionalStatusUpdateEvent } from '../../events/ConditionalStatusUpdateEvent'
import { ConditionalStatusSearchEvent } from '../../events/ConditionalStatusSearchEvent'
import { ConditionalStatusRelations } from '../../models/ConditionalStatusRelations.model'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { ConditionalStatusGroupService } from '../ConditionalStatusGroupService/ConditionalStatusGroupService'

export class ConditionalStatusService {
  /**
   * @description Search for a single conditionalStatus by id
   *
   * @param id
   * @param relations
   *
   * @returns promise for a ConditionalStatus
   */
  public async get({
    id,
    relations,
  }: {
    id?: number
    relations?: ConditionalStatusRelations[]
  }): Promise<ConditionalStatus> {
    const params = {
      id,
      relations:
        Array.isArray(relations) && !relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : relations?.join(',') || undefined,
    }
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `conditional-status/get`,
      params,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const conditionalStatusRaw = await flowfeedApiQueryService.get<ConditionalStatusDTO>(flowfeedApiQueryRequest)
      return ConditionalStatusService.formatConditionalStatus(conditionalStatusRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: ConditionalStatusSearchEvent): Promise<PaginatedResponse<ConditionalStatus>> {
    const params = {
      ...event,
      orderDirection: event.orderDirection?.toUpperCase(),
      relations:
        Array.isArray(event.relations) && !event.relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : event.relations?.join(',') || undefined,
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'conditional-status',
      params,
    }

    const conditionalStatuses: {
      data: ConditionalStatus[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawConditionalStatuses = await flowfeedApiQueryService.get<PaginatedResponse<ConditionalStatusDTO>>(
        flowfeedApiQueryRequest,
      )
      // wrap to avoid errors if the request is cancelled
      if (rawConditionalStatuses?.data) {
        conditionalStatuses.data = rawConditionalStatuses.data.map((conditionalStatus) =>
          ConditionalStatusService.formatConditionalStatus(conditionalStatus),
        )
        conditionalStatuses.total = rawConditionalStatuses.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return conditionalStatuses
  }

  public async add(conditionalStatus: ConditionalStatusAddEvent): Promise<ConditionalStatus> {
    const flowfeedApiQueryRequest = {
      endpoint: 'conditional-status',
      data: conditionalStatus,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const conditionalStatusRaw = await flowfeedApiQueryService.post<ConditionalStatusDTO>(flowfeedApiQueryRequest)
      return ConditionalStatusService.formatConditionalStatus(conditionalStatusRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, conditionalStatus: ConditionalStatusUpdateEvent): Promise<ConditionalStatus> {
    const flowfeedApiQueryRequest = {
      endpoint: `conditional-status/${id}`,
      data: conditionalStatus,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const conditionalStatusRaw = await flowfeedApiQueryService.patch<ConditionalStatusDTO>(flowfeedApiQueryRequest)
      return ConditionalStatusService.formatConditionalStatus(conditionalStatusRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `conditional-status/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    conditionalStatuses: (Partial<ConditionalStatus> & { conditionalStatusGroupId?: number })[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = conditionalStatuses.map((conditionalStatus) => {
      const numberTypeProps = [
        'id',
        'precipMin',
        'precipMax',
        'lookbackHoursMin',
        'lookbackHoursMax',
        'status',
        'conditionalStatusGroupId',
        'sortWeight',
      ]
      const formattedSponsor = formatToNumberTypeProps(conditionalStatus, numberTypeProps)
      return formattedSponsor
    })
    const flowfeedApiQueryRequest = {
      endpoint: `conditional-status/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public static formatConditionalStatus(conditionalStatus: ConditionalStatusDTO): ConditionalStatus {
    return {
      id: conditionalStatus.id,
      region: conditionalStatus.region,
      conditionalStatusGroup: conditionalStatus.conditionalStatusGroup
        ? ConditionalStatusGroupService.formatConditionalStatusGroup(conditionalStatus.conditionalStatusGroup)
        : undefined,
      precipMin: conditionalStatus.precipMin,
      precipMax: conditionalStatus.precipMax,
      lookbackHoursMin: conditionalStatus.lookbackHoursMin,
      lookbackHoursMax: conditionalStatus.lookbackHoursMax,
      status: conditionalStatus.status,
      sortWeight: conditionalStatus.sortWeight,
      updatedAt: new Date(conditionalStatus.updatedAt),
      createdAt: new Date(conditionalStatus.createdAt),
    }
  }
}
