import { formatToNumberTypeProps } from '../../utils/formatting'

import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { ConditionalStatusGroup } from '../../models/ConditionalStatusGroup.model'
import { ConditionalStatusGroupDTO } from '../../models/ConditionalStatusGroupDTO.model'
import { ConditionalStatusGroupAddEvent } from '../../events/ConditionalStatusGroupAddEvent'
import { ConditionalStatusGroupUpdateEvent } from '../../events/ConditionalStatusGroupUpdateEvent'
import { ConditionalStatusGroupSearchEvent } from '../../events/ConditionalStatusGroupSearchEvent'
import { ConditionalStatusGroupRelations } from '../../models/ConditionalStatusGroupRelations.model'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { ConditionalStatusService } from '../ConditionalStatusService/ConditionalStatusService'
import { TrailService } from '../TrailService/TrailService'

export class ConditionalStatusGroupService {
  /**
   * @description Search for a single conditionalStatusGroup by id
   *
   * @param id
   * @param relations
   *
   * @returns promise for a ConditionalStatusGroup
   */
  public async get({
    id,
    relations,
  }: {
    id?: number
    relations?: ConditionalStatusGroupRelations[]
  }): Promise<ConditionalStatusGroup> {
    const params = {
      id,
      relations:
        Array.isArray(relations) && !relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : relations?.join(',') || undefined,
    }
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `conditional-status-group/get`,
      params,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const conditionalStatusGroupRaw = await flowfeedApiQueryService.get<ConditionalStatusGroupDTO>(
        flowfeedApiQueryRequest,
      )
      return ConditionalStatusGroupService.formatConditionalStatusGroup(conditionalStatusGroupRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: ConditionalStatusGroupSearchEvent): Promise<PaginatedResponse<ConditionalStatusGroup>> {
    const params = {
      ...event,
      name: event.name || undefined,
      orderDirection: event.orderDirection?.toUpperCase(),
      relations:
        Array.isArray(event.relations) && !event.relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : event.relations?.join(',') || undefined,
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'conditional-status-group',
      params,
    }

    const conditionalStatusGroups: {
      data: ConditionalStatusGroup[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawConditionalStatusGroups = await flowfeedApiQueryService.get<
        PaginatedResponse<ConditionalStatusGroupDTO>
      >(flowfeedApiQueryRequest)
      // wrap to avoid errors if the request is cancelled
      if (rawConditionalStatusGroups?.data) {
        conditionalStatusGroups.data = rawConditionalStatusGroups.data.map((conditionalStatusGroup) =>
          ConditionalStatusGroupService.formatConditionalStatusGroup(conditionalStatusGroup),
        )
        conditionalStatusGroups.total = rawConditionalStatusGroups.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return conditionalStatusGroups
  }

  public async add(conditionalStatusGroup: ConditionalStatusGroupAddEvent): Promise<ConditionalStatusGroup> {
    const flowfeedApiQueryRequest = {
      endpoint: 'conditional-status-group',
      data: conditionalStatusGroup,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const conditionalStatusGroupRaw = await flowfeedApiQueryService.post<ConditionalStatusGroupDTO>(
        flowfeedApiQueryRequest,
      )
      return ConditionalStatusGroupService.formatConditionalStatusGroup(conditionalStatusGroupRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(
    id: number,
    conditionalStatusGroup: ConditionalStatusGroupUpdateEvent,
  ): Promise<ConditionalStatusGroup> {
    const flowfeedApiQueryRequest = {
      endpoint: `conditional-status-group/${id}`,
      data: conditionalStatusGroup,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const conditionalStatusGroupRaw = await flowfeedApiQueryService.patch<ConditionalStatusGroupDTO>(
        flowfeedApiQueryRequest,
      )
      return ConditionalStatusGroupService.formatConditionalStatusGroup(conditionalStatusGroupRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `conditional-status-group/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    conditionalStatusGroups: Partial<ConditionalStatusGroup>[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = conditionalStatusGroups.map((conditionalStatusGroup) => {
      const numberTypeProps = ['id']
      const formattedSponsor = formatToNumberTypeProps(conditionalStatusGroup, numberTypeProps)
      return formattedSponsor
    })
    const flowfeedApiQueryRequest = {
      endpoint: `conditional-status-group/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public static formatConditionalStatusGroup(
    conditionalStatusGroup: ConditionalStatusGroupDTO,
  ): ConditionalStatusGroup {
    return {
      id: conditionalStatusGroup.id,
      region: conditionalStatusGroup.region,
      name: conditionalStatusGroup.name,
      trails: Array.isArray(conditionalStatusGroup.trails)
        ? conditionalStatusGroup.trails.map(TrailService.formatTrail)
        : undefined,
      conditionalStatuses: Array.isArray(conditionalStatusGroup.conditionalStatuses)
        ? conditionalStatusGroup.conditionalStatuses.map(ConditionalStatusService.formatConditionalStatus)
        : undefined,
      updatedAt: new Date(conditionalStatusGroup.updatedAt),
      createdAt: new Date(conditionalStatusGroup.createdAt),
    }
  }
}
