import { useEffect, useMemo, useState } from 'react'
import { isEqual, camelCase, startCase } from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { SnackbarContext } from '../../../providers/SnackbarContext'
import { AppStateContext } from '../../../providers/AppStateContext'
import { generatePermalink } from '../../../utils/formatting'
import { IntRange } from '../../../utils/typing'

import { Trail } from '../../../models/Trail.model'
import { TrailAddEvent } from '../../../events/TrailAddEvent'
import { TrailUpdateEvent } from '../../../events/TrailUpdateEvent'
import { AppPermissions } from '../../../models/AppPermissions.model'
import { USStates } from '../../../models/USStates.model'
import { TrailSystem } from '../../../models/TrailSystem.model'
import { TrailRelations } from '../../../models/TrailRelations.model'
import { Difficulties } from '../../../models/Difficulties.model'

import UserService from '../../../services/UserService/UserService'
import { WeatherGroupService } from '../../../services/WeatherGroupService/WeatherGroupService'
import { TrailSystemService } from '../../../services/TrailSystemService/TrailSystemService'
import { TrailService } from '../../../services/TrailService/TrailService'

import {
  Stack,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  Typography,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import DetailSelectWithCreateNew from '../../../components/DetailSelectWithCreateNew/DetailSelectWithCreateNew.component'
import { useAppNavigate } from '../../../utils/url'
import { ConditionalStatusGroupService } from '../../../services/ConditionalStatusGroupService/ConditionalStatusGroupService'

export type TrailFormProps = {
  trail?: Trail
  trailSystem?: TrailSystem
  onAdd?: (trail: Trail) => void
  onEdit?: (trail: Trail) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
  disableRedirect?: boolean
}
const TrailForm = (props: TrailFormProps) => {
  const { trail, trailSystem, onCancel, onAdd, onEdit, saveTrigger, disableRedirect = false } = props

  const navigate = useAppNavigate()

  const [isLoading, setIsLoading] = useState(true)

  const { user } = useAuth0()
  const hasViewWeatherGroupPermissions = UserService.hasPermissions(user, [AppPermissions.viewWeatherGroup])
  const hasViewConditionalStatusGroupPermissions = UserService.hasPermissions(user, [
    AppPermissions.viewConditionalStatus,
  ])

  const isAdding = typeof onAdd === 'function'
  const isEditing = typeof onEdit === 'function'

  const [tempTrail, setTempTrail] = useState<
    Partial<Trail> & {
      weatherGroupId?: number | null
      displayWeatherGroupId?: number | null
      conditionalStatusGroupIds?: number[]
    }
  >(
    {
      ...trail,
      weatherGroupId: trail?.weatherGroup?.id,
      displayWeatherGroupId: trail?.displayWeatherGroup?.id,
      conditionalStatusGroupIds: trail?.conditionalStatusGroups?.map((each) => each.id),
    } || {},
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  const queryClient = useQueryClient()

  const weatherGroupService = useMemo(() => new WeatherGroupService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingWeatherGroups, data: weatherGroupsResponse } = useQuery(
    ['WeatherGroupService.search', { pageSize: 0 }, AppStateContext.getRegion()],
    () => weatherGroupService.search({ pageSize: 0 }),
    {
      enabled: hasViewWeatherGroupPermissions, // prevent the query from running unless they have the permissions
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch weather groups for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )

  const conditionalStatusGroupService = useMemo(() => new ConditionalStatusGroupService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingConditionalStatusGroups, data: conditionalStatusGroupsResponse } = useQuery(
    ['ConditionalStatusGroupService.search', { pageSize: 0 }, AppStateContext.getRegion()],
    () => conditionalStatusGroupService.search({ pageSize: 0 }),
    {
      enabled: hasViewConditionalStatusGroupPermissions, // prevent the query from running unless they have the permissions
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch conditional status groups for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )

  const [tempTrailSystem, setTempTrailSystem] = useState<TrailSystem | undefined>(trailSystem)
  const [isLoadingTrailSystems, setIsLoadingTrailSystems] = useState(true)
  const trailSystemService = useMemo(() => new TrailSystemService(), []) // memo to prevent re-creating service on every render
  const { data: trailSystemsResponse } = useQuery(
    ['TrailSystemService.search', { pageSize: 0 }, AppStateContext.getRegion()],
    () => trailSystemService.search({ pageSize: 0 }),
    {
      enabled: !trail && !trailSystem, // only query if we are adding a trail and no trail system was provided
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch trail systems for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )
  // useEffect usage instead of onSuccess or onSettled to ensure we always trigger even when the query is cached
  useEffect(() => {
    if (trail || trailSystem || trailSystemsResponse) {
      setIsLoadingTrailSystems(false)
    }
  }, [trail, trailSystem, trailSystemsResponse])

  const trailService = useMemo(() => new TrailService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingTrailWithRelations, data: trailWithRelations } = useQuery(
    [
      'TrailService.get',
      {
        id: trail?.id,
        relations: [TrailRelations.system, TrailRelations.weatherGroups, TrailRelations.conditionalStatusGroups],
      },
      AppStateContext.getRegion(),
    ],
    () =>
      trailService.get({
        id: trail?.id,
        relations: [TrailRelations.system, TrailRelations.weatherGroups, TrailRelations.conditionalStatusGroups],
      }),
    {
      enabled: trail?.id && (!trailSystem || !trail.weatherGroup?.id) ? true : false, // only query if we are editing a trail and no trail system was provided or provided trail is missing weather group relations
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch relation records from trail for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )
  // useEffect usage instead of onSuccess or onSettled to ensure we always trigger even when the query is cached
  useEffect(() => {
    if (!trailSystem) {
      setTempTrailSystem(trailWithRelations?.system)
      setIsLoadingTrailSystems(false)
    }
    if (!trail?.weatherGroup?.id) {
      setTempTrail({
        ...tempTrail,
        weatherGroup: trailWithRelations?.weatherGroup,
        displayWeatherGroup: trailWithRelations?.displayWeatherGroup,
        weatherGroupId: trailWithRelations?.weatherGroup?.id,
        displayWeatherGroupId: trailWithRelations?.displayWeatherGroup?.id,
        conditionalStatusGroupIds: trailWithRelations?.conditionalStatusGroups?.map((each) => each.id),
      })
    }
  }, [trailWithRelations])

  useEffect(() => {
    // if the trailSystem is not provided, try to look it up
    if (!trailSystem && trail && trail.system) {
      // if the trail is provided and has the system prop, no need to look up
      setTempTrailSystem(trail.system)
      setIsLoadingTrailSystems(false)
    }
  }, [trailSystem, trail])

  // fetch surface type options for the select
  const { isLoading: isLoadingSurfaceTypes, data: surfaceTypes } = useQuery(
    ['TrailService.listSurfaceTypes', AppStateContext.getRegion()],
    () => trailService.listSurfaceTypes(),
    {
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch surface types for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )

  // consolidate the various loading states into one
  useEffect(() => {
    const shouldBeLoadingTrailWithRelations = trail?.id && (!trailSystem || !trail.weatherGroup?.id) ? true : false
    setIsLoading(true)
    if (
      ((shouldBeLoadingTrailWithRelations && !isLoadingTrailWithRelations) || !shouldBeLoadingTrailWithRelations) &&
      ((hasViewWeatherGroupPermissions && !isLoadingWeatherGroups) || !hasViewWeatherGroupPermissions) &&
      ((hasViewConditionalStatusGroupPermissions && !isLoadingConditionalStatusGroups) ||
        !hasViewConditionalStatusGroupPermissions) &&
      !isLoadingSurfaceTypes &&
      !isLoadingTrailSystems
    ) {
      setIsLoading(false)
    }
  }, [
    trail,
    trailSystem,
    isLoadingTrailWithRelations,
    hasViewWeatherGroupPermissions,
    isLoadingWeatherGroups,
    isLoadingConditionalStatusGroups,
    isLoadingSurfaceTypes,
    isLoadingTrailSystems,
  ])

  const addTrail = useMutation(
    (mutationParams: { trail: TrailAddEvent }) => {
      setIsSubmitting(true)
      return trailService.add(mutationParams.trail)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail added successfully!')

        if (!disableRedirect) {
          // redirect to the new trail system
          navigate(`/trails/${result.id}`)
          // remove the search query to prevent auto refresh of the search results in case we are on that page and redirecting
          queryClient.removeQueries(['TrailService.search'])
        }
        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateTrail = useMutation(
    (mutationParams: { id: number; changedProperties: TrailUpdateEvent }) => {
      setIsSubmitting(true)
      return trailService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!tempTrail.name) {
      SnackbarContext.show(`Trail name is required`, 'error')
      return
    }
    if (!tempTrail.street) {
      SnackbarContext.show(`Trail street is required`, 'error')
      return
    }
    if (!tempTrail.city) {
      SnackbarContext.show(`Trail city is required`, 'error')
      return
    }
    if (!tempTrail.state) {
      SnackbarContext.show(`Trail state is required`, 'error')
      return
    }
    if (!tempTrail.weatherResistance) {
      SnackbarContext.show(`Trail weather resistance is required`, 'error')
      return
    }

    if (!trail) {
      if (!tempTrailSystem?.id) {
        SnackbarContext.show(`Trail system is required`, 'error')
        return
      }

      // format the data to match the API
      const formattedTrail: TrailAddEvent = {
        name: tempTrail.name,
        systemId: tempTrailSystem.id,
        weatherGroupId: tempTrail.weatherGroupId || undefined,
        displayWeatherGroupId: tempTrail.displayWeatherGroupId || undefined,
        conditionalStatusGroupIds: tempTrail.conditionalStatusGroupIds || undefined,
        difficultyId: tempTrail.difficultyId || undefined,
        description: tempTrail.description || undefined,
        featuredImgUrl: tempTrail.featuredImgUrl || undefined,
        surfaceType: tempTrail.surfaceType || undefined,
        distance: tempTrail.distance || undefined,
        descent: tempTrail.descent || undefined,
        climb: tempTrail.climb || undefined,
        gpsLat: tempTrail.gpsLat || undefined,
        gpsLong: tempTrail.gpsLong || undefined,
        parkingGpsLat: tempTrail.parkingGpsLat || undefined,
        parkingGpsLong: tempTrail.parkingGpsLong || undefined,
        street: tempTrail.street,
        city: tempTrail.city,
        state: tempTrail.state,
        weatherResistance: tempTrail.weatherResistance,
        saturationModifier: tempTrail.saturationModifier || undefined,
        freezeModifier: tempTrail.freezeModifier || undefined,
        thawModifier: tempTrail.thawModifier || undefined,
        tfTrailUrl: tempTrail.tfTrailUrl || undefined,
        sortWeight: tempTrail.sortWeight || undefined,
        permalink: tempTrail.permalink || undefined,
        active: tempTrail.active,
      }

      // adding a new trail
      addTrail.mutate({ trail: formattedTrail })
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof Trail) => {
        return tempTrail[field] !== undefined && tempTrail[field] !== trail[field]
      }

      if (!tempTrail.permalink) {
        SnackbarContext.show(`Trail permalink cannot be blank`, 'error')
        return
      }

      // determine the original weather group id based on whether it was passed in or we had to fetch it
      const originalWeatherGroupId =
        trail && !trail?.weatherGroup?.id ? trailWithRelations?.weatherGroup?.id : trail?.weatherGroup?.id
      const originalDisplayWeatherGroupId =
        trail && !trail?.displayWeatherGroup?.id
          ? trailWithRelations?.displayWeatherGroup?.id
          : trail?.displayWeatherGroup?.id

      const changedProperties: TrailUpdateEvent = {
        id: trail.id,
        name: didChange('name') ? tempTrail.name : undefined,
        weatherGroupId:
          tempTrail.weatherGroupId !== originalWeatherGroupId ? tempTrail.weatherGroupId || null : undefined,
        displayWeatherGroupId:
          tempTrail.displayWeatherGroupId !== originalDisplayWeatherGroupId
            ? tempTrail.displayWeatherGroupId || null
            : undefined,
        conditionalStatusGroupIds: !isEqual(
          tempTrail.conditionalStatusGroupIds,
          trail.conditionalStatusGroups?.map((conditionalStatusGroup) => conditionalStatusGroup.id),
        )
          ? tempTrail.conditionalStatusGroupIds
          : undefined,
        difficultyId: tempTrail.difficultyId || undefined,
        description: didChange('description') ? tempTrail.description : undefined,
        featuredImgUrl: didChange('featuredImgUrl') ? tempTrail.featuredImgUrl : undefined,
        surfaceType: didChange('surfaceType') ? tempTrail.surfaceType : undefined,
        distance: didChange('distance') ? tempTrail.distance : undefined,
        descent: didChange('descent') ? tempTrail.descent : undefined,
        climb: didChange('climb') ? tempTrail.climb : undefined,
        gpsLat: didChange('gpsLat') ? tempTrail.gpsLat : undefined,
        gpsLong: didChange('gpsLong') ? tempTrail.gpsLong : undefined,
        parkingGpsLat: didChange('parkingGpsLat') ? tempTrail.parkingGpsLat : undefined,
        parkingGpsLong: didChange('parkingGpsLong') ? tempTrail.parkingGpsLong : undefined,
        street: didChange('street') ? tempTrail.street : undefined,
        city: didChange('city') ? tempTrail.city : undefined,
        state: didChange('state') ? tempTrail.state : undefined,
        weatherResistance: didChange('weatherResistance') ? tempTrail.weatherResistance : undefined,
        saturationModifier: didChange('saturationModifier') ? tempTrail.saturationModifier : undefined,
        freezeModifier: didChange('freezeModifier') ? tempTrail.freezeModifier : undefined,
        thawModifier: didChange('thawModifier') ? tempTrail.thawModifier : undefined,
        tfTrailUrl: didChange('tfTrailUrl') ? tempTrail.tfTrailUrl : undefined,
        sortWeight: didChange('sortWeight') ? tempTrail.sortWeight : undefined,
        permalink: didChange('permalink') ? tempTrail.permalink : undefined,
        active: didChange('active') ? tempTrail.active : undefined,
      }

      // edit an existing trail
      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        updateTrail.mutate({ id: trail.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {(isSubmitting || isLoading) && <Loading sx={{ py: 20 }} />}
      {!isSubmitting && !isLoading && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            {isAdding && !trailSystem ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="trail-system-select-label">Trail System</InputLabel>
                <Select
                  labelId="trail-system-select-label"
                  id="trail-system-select"
                  value={tempTrailSystem ? tempTrailSystem.id : ''}
                  label="Trail System"
                  onChange={(e) => {
                    setTempTrailSystem(
                      trailSystemsResponse?.data.find((eachTrailSystem) => eachTrailSystem.id === e.target.value),
                    )
                  }}
                >
                  <MenuItem value="">-Select-</MenuItem>
                  {trailSystemsResponse?.data.map((eachTrailSystem) => {
                    return (
                      <MenuItem key={eachTrailSystem.id} value={eachTrailSystem.id}>
                        {eachTrailSystem.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            ) : (
              <Typography variant="h6">in {tempTrailSystem?.name}</Typography>
            )}
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Name</InputLabel>
              <OutlinedInput
                id="trail-name"
                label="Name"
                value={tempTrail.name || ''}
                required
                onChange={(e) => {
                  setTempTrail((prev) => ({ ...prev, name: e.target.value }))
                }}
                onKeyDown={keyDownSubmit}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Permalink</InputLabel>
              <OutlinedInput
                id="trail-permalink"
                label="Permalink"
                value={tempTrail.permalink || ''}
                required={isEditing}
                startAdornment={
                  <InputAdornment position="start">
                    https://{AppStateContext.getRegion().toLowerCase()}.flowfeed.app/trail-system/
                    {tempTrailSystem?.permalink || 'parent-trail'}/
                  </InputAdornment>
                }
                onChange={(e) => {
                  setTempTrail((prev) => ({ ...prev, permalink: generatePermalink(e.target.value) }))
                }}
                onKeyDown={keyDownSubmit}
              />
              <FormHelperText
                sx={{
                  mb: 1, // extra space to compensate for the helper text visually separate from the next item's label
                }}
              >
                This is the unique identifier for this trail used in the URL for this trail's page within the app. It
                can only contain letters, numbers, and dashes. Invalid characters will be removed.
                {isAdding ? ' If you leave this blank, one will be generated for you.' : ''}
              </FormHelperText>
            </FormControl>
            <DetailInput<Trail>
              label="Featured Image URL"
              field="featuredImgUrl"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="Description"
              field="description"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-difficulty-select-label">Difficulty</InputLabel>
              <Select
                labelId="trail-difficulty-select-label"
                id="trail-difficulty-select"
                value={tempTrail.difficultyId || ''}
                label="Difficulty"
                onChange={(e) => {
                  setTempTrail((prev) => ({ ...prev, difficultyId: e.target.value as Difficulties }))
                }}
              >
                {Object.entries(Difficulties).map((eachDifficulty) => {
                  return (
                    <MenuItem key={eachDifficulty[1]} value={eachDifficulty[1]}>
                      {startCase(camelCase(eachDifficulty[0]))}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <DetailSelectWithCreateNew<Trail>
              label="Surface Type"
              field="surfaceType"
              options={surfaceTypes || []}
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="Distance (miles)"
              field="distance"
              type="number"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="Descent (feet)"
              field="descent"
              type="number"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="Climb (feet)"
              field="climb"
              type="number"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="Street"
              field="street"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="City"
              field="city"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-state-select-label">State</InputLabel>
              <Select
                labelId="trail-state-select-label"
                id="trail-state-select"
                value={tempTrail.state || ''}
                label="State"
                onChange={(e) => {
                  setTempTrail((prev) => ({ ...prev, state: e.target.value as USStates }))
                }}
              >
                {Object.entries(USStates).map((eachState) => {
                  return (
                    <MenuItem key={eachState[1]} value={eachState[1]}>
                      {startCase(camelCase(eachState[0]))}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <DetailInput<Trail>
              label="GPS Latitude"
              field="gpsLat"
              type="number"
              placeholder="e.g. 42.3601"
              helperText="Up to 15 points of precision are supported for GPS latitude."
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="GPS Longitude"
              field="gpsLong"
              type="number"
              placeholder="e.g. 42.3601"
              helperText="Up to 15 points of precision are supported for GPS latitude."
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="Parking GPS Latitude"
              field="parkingGpsLat"
              type="number"
              placeholder="e.g. 42.3601"
              helperText="Up to 15 points of precision are supported for GPS latitude."
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="Parking GPS Longitude"
              field="parkingGpsLong"
              type="number"
              placeholder="e.g. 42.3601"
              helperText="Up to 15 points of precision are supported for GPS latitude."
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            {hasViewWeatherGroupPermissions && weatherGroupsResponse?.data && (
              <>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="trail-weather-group-select-label">Weather Group</InputLabel>
                  <Select
                    labelId="trail-weather-group-select-label"
                    id="trail-weather-group-select"
                    value={tempTrail.weatherGroupId || ''}
                    label="Weather Group"
                    onChange={(e) => {
                      setTempTrail((prev) => ({
                        ...prev,
                        weatherGroupId: e.target.value ? Number(e.target.value) : null,
                      }))
                    }}
                  >
                    <MenuItem value="">-Inherit from parent-</MenuItem>
                    {weatherGroupsResponse.data.map((eachWeatherGroup) => {
                      return (
                        <MenuItem key={eachWeatherGroup.id} value={eachWeatherGroup.id}>
                          {eachWeatherGroup.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText
                    sx={{
                      mb: 1, // extra space to compensate for the helper text visually separate from the next item's label
                    }}
                  >
                    If you leave this blank, the weather group from the parent trail system will be used
                  </FormHelperText>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="trail-display-weather-group-select-label">Display Weather Group</InputLabel>
                  <Select
                    labelId="trail-display-weather-group-select-label"
                    id="trail-display-weather-group-select"
                    value={tempTrail.displayWeatherGroupId || ''}
                    label="Display Weather Group"
                    onChange={(e) => {
                      setTempTrail((prev) => ({
                        ...prev,
                        displayWeatherGroupId: e.target.value ? Number(e.target.value) : null,
                      }))
                    }}
                  >
                    <MenuItem value="">-Inherit from parent-</MenuItem>
                    {weatherGroupsResponse.data.map((eachWeatherGroup) => {
                      return (
                        <MenuItem key={eachWeatherGroup.id} value={eachWeatherGroup.id}>
                          {eachWeatherGroup.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText
                    sx={{
                      mb: 1, // extra space to compensate for the helper text visually separate from the next item's label
                    }}
                  >
                    If you leave this blank, the weather group from the parent trail system will be used
                  </FormHelperText>
                </FormControl>
              </>
            )}
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-weather-resistance-select-label">Weather Resistance</InputLabel>
              <Select
                labelId="trail-weather-resistance-select-label"
                id="trail-weather-resistance-select"
                value={tempTrail.weatherResistance || 5}
                label="Weather Resistance"
                onChange={(e) => {
                  const value = Number(e.target.value)
                  setTempTrail((prev) => ({
                    ...prev,
                    weatherResistance: value >= 1 && value <= 12 ? (value as IntRange<1, 13>) : undefined,
                  }))
                }}
              >
                {Array.from({ length: 12 }, (value, index) => index + 1).map((eachResistance) => {
                  return (
                    <MenuItem key={eachResistance} value={eachResistance}>
                      {eachResistance}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <DetailInput<Trail>
              label="Saturation Modifier"
              field="saturationModifier"
              type="number"
              helperText="e.g. 0.5 for 50% or 1.5 for 150%"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="Freeze Modifier"
              field="freezeModifier"
              type="number"
              helperText="e.g. 0.5 for 50% or 1.5 for 150%"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="Thaw Modifier"
              field="thawModifier"
              type="number"
              helperText="e.g. 0.5 for 50% or 1.5 for 150%"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            {hasViewConditionalStatusGroupPermissions && conditionalStatusGroupsResponse?.data && (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="trail-conditional-status-groups-select-label">Conditional Status Groups</InputLabel>
                <Select
                  labelId="trail-conditional-status-groups-select-label"
                  id="trail-conditional-status-groups-select"
                  value={tempTrail.conditionalStatusGroupIds || []}
                  label="Conditional Status Groups"
                  multiple
                  onChange={(e) => {
                    setTempTrail((prev) => ({
                      ...prev,
                      conditionalStatusGroupIds:
                        typeof e.target.value === 'string'
                          ? e.target.value.split(',').map((val) => Number(val))
                          : e.target.value,
                    }))
                  }}
                >
                  {conditionalStatusGroupsResponse.data.map((eachConditionalStatusGroup) => {
                    return (
                      <MenuItem key={eachConditionalStatusGroup.id} value={eachConditionalStatusGroup.id}>
                        {eachConditionalStatusGroup.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}
            <DetailInput<Trail>
              label="Trailforks URL"
              field="tfTrailUrl"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<Trail>
              label="Sort Weight"
              field="sortWeight"
              type="number"
              helperText="Lower numbers will appear first in lists (default 99)"
              tempDetail={tempTrail}
              setTempDetail={setTempTrail}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-active-select-label">Active</InputLabel>
              <Select
                labelId="trail-active-select-label"
                id="trail-active-select"
                value={tempTrail.active === false ? 'false' : 'true'}
                label="Active"
                onChange={(e) => {
                  setTempTrail((prev) => ({ ...prev, active: e.target.value === 'false' ? false : true }))
                }}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default TrailForm
