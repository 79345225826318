import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { ConditionalStatusGroup } from '../../../models/ConditionalStatusGroup.model'
import { ConditionalStatusGroupAddEvent } from '../../../events/ConditionalStatusGroupAddEvent'
import { ConditionalStatusGroupUpdateEvent } from '../../../events/ConditionalStatusGroupUpdateEvent'

import { ConditionalStatusGroupService } from '../../../services/ConditionalStatusGroupService/ConditionalStatusGroupService'

import { Stack, Button } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import { useAppNavigate } from '../../../utils/url'

export type ConditionalStatusGroupFormProps = {
  conditionalStatusGroup?: ConditionalStatusGroup
  onAdd?: (conditionalStatusGroup: ConditionalStatusGroup) => void
  onEdit?: (conditionalStatusGroup: ConditionalStatusGroup) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
  disableRedirect?: boolean
}
const ConditionalStatusGroupForm = (props: ConditionalStatusGroupFormProps) => {
  const { conditionalStatusGroup, onCancel, onAdd, onEdit, saveTrigger, disableRedirect = false } = props

  const navigate = useAppNavigate()

  const [tempConditionalStatusGroup, setTempConditionalStatusGroup] = useState<Partial<ConditionalStatusGroup>>(
    conditionalStatusGroup || {},
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  const conditionalStatusGroupService = useMemo(() => new ConditionalStatusGroupService(), []) // memo to prevent re-creating service on every render

  const queryClient = useQueryClient()

  const addConditionalStatusGroup = useMutation(
    (mutationParams: { conditionalStatusGroup: ConditionalStatusGroupAddEvent }) => {
      setIsSubmitting(true)
      return conditionalStatusGroupService.add(mutationParams.conditionalStatusGroup)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Conditional Status Group added successfully!')

        if (!disableRedirect) {
          // redirect to the new conditionalStatusGroup
          navigate(`/conditional-status-groups/${result.id}`)
          // remove the search query to prevent auto refresh of the search results in case we are on that page and redirecting
          queryClient.removeQueries(['ConditionalStatusGroupService.search'])
        }
        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Conditional Status Group failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateConditionalStatusGroup = useMutation(
    (mutationParams: { id: number; changedProperties: ConditionalStatusGroupUpdateEvent }) => {
      setIsSubmitting(true)
      return conditionalStatusGroupService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Conditional Status Group updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Conditional Status Group failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!tempConditionalStatusGroup.name) {
      SnackbarContext.show(`Conditional Status Group name text is required`, 'error')
      return
    }

    setIsSubmitting(true)

    if (!conditionalStatusGroup) {
      // format the data to match the API
      const formattedConditionalStatusGroup: ConditionalStatusGroupAddEvent = {
        name: tempConditionalStatusGroup.name,
      }

      // adding a new record
      addConditionalStatusGroup.mutate({ conditionalStatusGroup: formattedConditionalStatusGroup })
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof ConditionalStatusGroup) => {
        return (
          tempConditionalStatusGroup[field] !== undefined &&
          tempConditionalStatusGroup[field] !== conditionalStatusGroup[field]
        )
      }

      const changedProperties: ConditionalStatusGroupUpdateEvent = {
        id: conditionalStatusGroup.id,
        name: didChange('name') ? tempConditionalStatusGroup.name : undefined,
      }

      // edit an existing record
      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        updateConditionalStatusGroup.mutate({ id: conditionalStatusGroup.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {isSubmitting && <Loading sx={{ py: 20 }} />}
      {!isSubmitting && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            <DetailInput<ConditionalStatusGroup>
              label="Name"
              field="name"
              tempDetail={tempConditionalStatusGroup}
              setTempDetail={setTempConditionalStatusGroup}
              keyDownSubmit={keyDownSubmit}
            />
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default ConditionalStatusGroupForm
