import { AppRegions } from './AppRegions.model'
import { ConditionalStatusGroup } from './ConditionalStatusGroup.model'

export type ConditionalStatus = {
  id: number
  region: AppRegions
  conditionalStatusGroup?: ConditionalStatusGroup
  precipMin: number
  precipMax: number
  lookbackHoursMin: number
  lookbackHoursMax: number
  status: 0 | 1 | 2 | 3
  sortWeight: number
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the ConditionalStatus type's fields in js/ts files
export const conditionalStatusFields: (keyof ConditionalStatus)[] = [
  'id',
  'region',
  'conditionalStatusGroup',
  'precipMin',
  'precipMax',
  'lookbackHoursMin',
  'lookbackHoursMax',
  'status',
  'sortWeight',
  'updatedAt',
  'createdAt',
]
