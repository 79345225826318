import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'

export class ConfigService {
  public async get(): Promise<Record<string, string>> {
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'config',
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      return flowfeedApiQueryService.get<Record<string, string>>(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async save(config: Record<string, string | null>): Promise<Record<string, string>> {
    const flowfeedApiQueryRequest = {
      endpoint: 'config',
      data: config,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      return flowfeedApiQueryService.post<Record<string, string>>(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }
}
