import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { Trail } from '../../models/Trail.model'
import { TrailDTO } from '../../models/TrailDTO.model'
import { TrailAddEvent } from '../../events/TrailAddEvent'
import { TrailUpdateEvent } from '../../events/TrailUpdateEvent'
import { TrailRelations } from '../../models/TrailRelations.model'
import { TrailSearchEvent } from '../../events/TrailSearchEvent'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { WeatherGroupService } from '../WeatherGroupService/WeatherGroupService'
import { TrailSystemService } from '../TrailSystemService/TrailSystemService'
import { formatToNumberTypeProps } from '../../utils/formatting'
import { ManualTrailStatusService } from '../ManualTrailStatusService/ManualTrailStatusService'
import { TrailFeatureService } from '../TrailFeatureService/TrailFeatureService'
import { SponsorService } from '../SponsorService/SponsorService'
import { TrailStatusService } from '../TrailStatusService/TrailStatusService'
import { ConditionalStatusGroupService } from '../ConditionalStatusGroupService/ConditionalStatusGroupService'

export class TrailService {
  /**
   * @description Search for a single trail by id or permalink
   *
   * @param id
   * @param permalink
   * @param relations
   *
   * @returns promise for a Trail
   */
  public async get({
    id,
    permalink,
    relations,
  }: {
    id?: number
    permalink?: string
    relations?: TrailRelations[]
  }): Promise<Trail> {
    const params = {
      id,
      permalink,
      relations:
        Array.isArray(relations) && !relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : relations?.join(',') || undefined,
    }
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `trail/get`,
      params,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const trailRaw = await flowfeedApiQueryService.get<TrailDTO>(flowfeedApiQueryRequest)
      return TrailService.formatTrail(trailRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: TrailSearchEvent): Promise<PaginatedResponse<Trail>> {
    const params = {
      ...event,
      name: event.name || undefined,
      orderDirection: event.orderDirection?.toUpperCase(),
      trailFeatureIds: event.trailFeatureIds?.join(',') || undefined,
      ids: event.ids?.join(',') || undefined,
      systemIds: event.systemIds?.join(',') || undefined,
      relations:
        Array.isArray(event.relations) && !event.relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : event.relations?.join(',') || undefined,
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'trail',
      params,
    }

    const trails: {
      data: Trail[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawTrails = await flowfeedApiQueryService.get<PaginatedResponse<TrailDTO>>(flowfeedApiQueryRequest)
      // wrap to avoid errors if the request is cancelled
      if (rawTrails?.data) {
        trails.data = rawTrails.data.map((trail) => TrailService.formatTrail(trail))
        trails.total = rawTrails.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return trails
  }

  public async add(trail: TrailAddEvent): Promise<Trail> {
    const flowfeedApiQueryRequest = {
      endpoint: 'trail',
      data: trail,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const trailRaw = await flowfeedApiQueryService.post<TrailDTO>(flowfeedApiQueryRequest)
      return TrailService.formatTrail(trailRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, trail: TrailUpdateEvent): Promise<Trail> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail/${id}`,
      data: trail,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const trailRaw = await flowfeedApiQueryService.patch<TrailDTO>(flowfeedApiQueryRequest)
      return TrailService.formatTrail(trailRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    trails: (Partial<Trail> & {
      systemId?: number
      weatherGroupId?: number | null
      displayWeatherGroupId?: number | null
      featureIds?: number[]
      conditionalStatusGroupIds?: number[]
    })[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = trails.map((trail) => {
      const numberTypeProps = [
        'id',
        'systemId',
        'weatherGroupId',
        'displayWeatherGroupId',
        'distance',
        'descent',
        'climb',
        'gpsLat',
        'gpsLong',
        'parkingGpsLat',
        'parkingGpsLong',
        'weatherResistance',
        'saturationModifier',
        'freezeModifier',
        'thawModifier',
        'sortWeight',
      ]
      return formatToNumberTypeProps(
        {
          ...trail,
          weatherGroupId: trail.weatherGroupId !== undefined ? trail.weatherGroupId || null : undefined,
          displayWeatherGroupId:
            trail.displayWeatherGroupId !== undefined ? trail.displayWeatherGroupId || null : undefined,
          active:
            trail.active === undefined
              ? undefined
              : typeof trail.active === 'boolean'
              ? trail.active
              : String(trail.active).toLowerCase() === 'true',
        },
        numberTypeProps,
      )
    })
    const flowfeedApiQueryRequest = {
      endpoint: `trail/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async listSurfaceTypes(): Promise<string[]> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail/surface-types`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const surfaceTypes = await flowfeedApiQueryService.get<string[]>(flowfeedApiQueryRequest)
      return surfaceTypes
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public static formatTrail(trail: TrailDTO): Trail {
    return {
      id: trail.id,
      region: trail.region,
      name: trail.name,
      system: trail.system ? TrailSystemService.formatTrailSystem(trail.system) : undefined,
      weatherGroup: trail.weatherGroup ? WeatherGroupService.formatWeatherGroup(trail.weatherGroup) : undefined,
      displayWeatherGroup: trail.displayWeatherGroup
        ? WeatherGroupService.formatWeatherGroup(trail.displayWeatherGroup)
        : undefined,
      conditionalStatusGroups: Array.isArray(trail.conditionalStatusGroups)
        ? trail.conditionalStatusGroups.map(ConditionalStatusGroupService.formatConditionalStatusGroup)
        : undefined,
      manualTrailStatuses: Array.isArray(trail.manualTrailStatuses)
        ? trail.manualTrailStatuses.map(ManualTrailStatusService.formatManualTrailStatus)
        : undefined,
      sponsor: trail.sponsor ? SponsorService.formatSponsor(trail.sponsor) : undefined,
      features: Array.isArray(trail.features) ? trail.features.map(TrailFeatureService.formatTrailFeature) : undefined,
      status: trail.status ? TrailStatusService.formatTrailStatus(trail.status) : undefined,
      difficultyId: trail.difficultyId,
      description: trail.description,
      featuredImgUrl: trail.featuredImgUrl,
      surfaceType: trail.surfaceType,
      distance: trail.distance,
      descent: trail.descent,
      climb: trail.climb,
      gpsLat: trail.gpsLat,
      gpsLong: trail.gpsLong,
      parkingGpsLat: trail.parkingGpsLat,
      parkingGpsLong: trail.parkingGpsLong,
      street: trail.street,
      city: trail.city,
      state: trail.state,
      weatherResistance: trail.weatherResistance,
      saturationModifier: trail.saturationModifier,
      freezeModifier: trail.freezeModifier,
      thawModifier: trail.thawModifier,
      tfTrailUrl: trail.tfTrailUrl,
      sortWeight: trail.sortWeight,
      permalink: trail.permalink,
      keywords: trail.keywords,
      active: trail.active,
      updatedAt: new Date(trail.updatedAt),
      createdAt: new Date(trail.createdAt),
    }
  }
}
