import { AppRegions } from './AppRegions.model'
import { ConditionalStatus } from './ConditionalStatus.model'
import { Trail } from './Trail.model'

export type ConditionalStatusGroup = {
  id: number
  region: AppRegions
  name: string
  trails?: Trail[]
  conditionalStatuses?: ConditionalStatus[]
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the ConditionalStatusGroup type's fields in js/ts files
export const conditionalStatusGroupFields: (keyof ConditionalStatusGroup)[] = [
  'id',
  'region',
  'name',
  'trails',
  'conditionalStatuses',
  'updatedAt',
  'createdAt',
]
