export enum AppPermissions {
  editConfig = 'edit:config',
  viewConditionalStatus = 'view:conditionalStatus',
  editConditionalStatus = 'edit:conditionalStatus',
  viewManualTrailStatus = 'view:manualTrailStatus',
  editManualTrailStatus = 'edit:manualTrailStatus',
  viewSponsor = 'view:sponsor',
  editSponsor = 'edit:sponsor',
  viewTrail = 'view:trail',
  editTrail = 'edit:trail',
  viewTrailFeature = 'view:trailFeature',
  editTrailFeature = 'edit:trailFeature',
  viewTrailSystem = 'view:trailSystem',
  editTrailSystem = 'edit:trailSystem',
  viewWeatherGroup = 'view:weatherGroup',
  editWeatherGroup = 'edit:weatherGroup',
  viewWeatherRecord = 'view:weatherRecord',
  editWeatherRecord = 'edit:weatherRecord',
  viewTrailFinder = 'view:trailFinder',
  editTrailFinder = 'edit:trailFinder',
  test = 'test',
}
