import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { AppLayoutContext } from '../../../providers/AppLayout'
import { useAppNavigate, useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'
import { formatDate, getObjectKeyFromValue } from '../../../utils/formatting'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { ConditionalStatus } from '../../../models/ConditionalStatus.model'
import { ConditionalStatusRelations } from '../../../models/ConditionalStatusRelations.model'

import UserService from '../../../services/UserService/UserService'
import { ConditionalStatusService } from '../../../services/ConditionalStatusService/ConditionalStatusService'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs.component'
import ConditionalStatusForm from '../shared/ConditionalStatusForm.component'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import { AppStateContext } from '../../../providers/AppStateContext'
import { TrailStatuses } from '../../../models/TrailStatuses.model'

const ConditionalStatusDetail = (): JSX.Element => {
  const navigate = useAppNavigate()
  const { id } = useParams<{ id: string }>()
  const [loadingError, setLoadingError] = useState('')

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editTrailFinder])

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useUrlStateParams<boolean>(
    false,
    'edit',
    (value) => (value ? 't' : ''),
    (value) => value === 't',
  )
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const conditionalStatusService = useMemo(() => new ConditionalStatusService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    AppLayoutContext.setPageName(`Conditional Status Detail`)
  }, [id])

  const queryClient = useQueryClient()
  const { isLoading: isLoadingConditionalStatus, data: conditionalStatus } = useQuery(
    [
      'ConditionalStatusService.get',
      {
        id: Number(id),
        relations: [ConditionalStatusRelations.conditionalStatusGroup],
      },
      AppStateContext.getRegion(),
    ],
    () =>
      conditionalStatusService.get({
        id: Number(id),
        relations: [ConditionalStatusRelations.conditionalStatusGroup],
      }),
    {
      onError: (err) => {
        setLoadingError(typeof err === 'string' ? err : 'Failed to load conditional status.')
        console.error(err)
      },
    },
  )

  // consolidate the various loading states into one
  useEffect(() => {
    setIsLoading(!isLoadingConditionalStatus ? false : true)
  }, [isLoadingConditionalStatus])

  const handleEdit = () => {
    setIsEditing(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDelete = () => {
    if (conditionalStatus?.id) {
      setIsSaving(true)
      setIsDeleteConfirmationOpen(false)
      conditionalStatusService
        .delete(conditionalStatus.id)
        .then(() => {
          SnackbarContext.show(`Conditional Status deleted successfully. You will now be redirected.`)

          // after a delay of 1 second, redirect to the conditionalStatus list page
          setTimeout(() => {
            navigate(`/conditional-status-groups/${conditionalStatus?.conditionalStatusGroup?.id}`)
            queryClient.removeQueries([
              'ConditionalStatusService.get',
              {
                id: Number(id),
                relations: [ConditionalStatusRelations.conditionalStatusGroup],
              },
            ])
            // invalidate all queries to ensure any queries that are using the modified record are updated
            queryClient.invalidateQueries()
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
          SnackbarContext.show(`Conditional Status failed to delete: ${err}`, 'error')
          setIsSaving(false)
        })
    }
  }

  return (
    <>
      {conditionalStatus && (
        <FormDialog<ConditionalStatus>
          open={isEditing}
          entity={conditionalStatus}
          entityLabel="Conditional Status"
          onClose={() => setIsEditing(false)}
        >
          <ConditionalStatusForm
            conditionalStatus={conditionalStatus}
            onEdit={handleEdit}
            onCancel={() => setIsEditing(false)}
          />
        </FormDialog>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this conditional status?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setIsDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ p: 2 }}>
        {isLoading && !loadingError && <Loading sx={{ py: 20 }} />}
        {loadingError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{loadingError}</Typography>
          </Stack>
        )}
        {!isLoading && !loadingError && conditionalStatus && (
          <>
            {isSaving && <Loading sx={{ py: 20 }} />}
            {!isSaving && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                  <Breadcrumbs
                    items={() => {
                      const breadcrumbs: Array<{ label: string; href?: string }> = [
                        {
                          label: 'Conditional Status Groups',
                          href: '/conditional-status-groups',
                        },
                      ]
                      if (conditionalStatus.conditionalStatusGroup?.id) {
                        breadcrumbs.push({
                          label: conditionalStatus.conditionalStatusGroup.name,
                          href: `/conditional-status-groups/${conditionalStatus.conditionalStatusGroup.id}`,
                        })
                      }
                      breadcrumbs.push({ label: `Conditional Status ID: ${conditionalStatus.id}` })

                      return breadcrumbs
                    }}
                  />
                  {hasWritePermissions && (
                    <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                      <Button variant="text" onClick={handleDeleteClick}>
                        Delete
                      </Button>
                      <Button variant="contained" onClick={() => setIsEditing(true)}>
                        Edit
                      </Button>
                    </Stack>
                  )}
                </Stack>
                <Box>
                  <Box>
                    <Typography variant="h6" component="h3">
                      ID: {conditionalStatus.id}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">
                      Status:{' '}
                      {getObjectKeyFromValue(TrailStatuses, conditionalStatus.status as TrailStatuses) ||
                        conditionalStatus.status}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Precip Min: {conditionalStatus.precipMin} mm</Typography>
                    <Typography variant="body1">Precip Max: {conditionalStatus.precipMax} mm</Typography>
                    <Typography variant="body1">Lookback Hrs Min: {conditionalStatus.lookbackHoursMin} hrs</Typography>
                    <Typography variant="body1">Lookback Hrs Max: {conditionalStatus.lookbackHoursMax} hrs</Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">
                      Sort Weight: {conditionalStatus.sortWeight} (Lower numbers are matched before higher numbers)
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Updated At: {formatDate(conditionalStatus.updatedAt)}</Typography>
                    <Typography variant="body1">Created At: {formatDate(conditionalStatus.createdAt)}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default ConditionalStatusDetail
