import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { AppPermissions } from '../../models/AppPermissions.model'

import UserService from '../../services/UserService/UserService'

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import HomeIcon from '@mui/icons-material/Home'
import CloudIcon from '@mui/icons-material/Cloud'
import RouteIcon from '@mui/icons-material/Route'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import ArtTrackIcon from '@mui/icons-material/ArtTrack'
import HandshakeIcon from '@mui/icons-material/Handshake'
import TuneIcon from '@mui/icons-material/Tune'
import SignPostIcon from '../../icons/SignPostIcon'
import SettingsIcon from '@mui/icons-material/Settings'
import { AppLink } from '../../utils/url'

type AppNavigationProps = {
  open: boolean
  closeDrawer: () => void
}
const AppNavigation = ({ open, closeDrawer }: AppNavigationProps) => {
  const location = useLocation()
  const { user } = useAuth0()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <List
      component="nav"
      sx={(theme) => ({
        '& .MuiListItemIcon-root': {
          color: 'inherit',
        },
        '& .MuiListItemButton-root.Mui-selected': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          ':hover': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
        '& .MuiListItemText-root': {
          whiteSpace: 'normal',
          ...(!open ? { display: 'none' } : undefined),
        },
      })}
    >
      <ListItem disablePadding>
        <ListItemButton
          component={AppLink}
          to="/"
          selected={location.pathname === '/'}
          onClick={() => (isMobile ? closeDrawer() : undefined)}
        >
          <ListItemIcon>
            <Tooltip title="Home" placement="right" PopperProps={{ sx: open ? { display: 'none' } : undefined }}>
              <HomeIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </ListItem>
      {UserService.hasPermissions(user, [AppPermissions.viewTrailSystem]) && (
        <ListItem disablePadding>
          <ListItemButton
            component={AppLink}
            to="/trail-systems"
            selected={location.pathname.startsWith('/trail-systems')}
            onClick={() => (isMobile ? closeDrawer() : undefined)}
          >
            <ListItemIcon>
              <Tooltip
                title="Trail Systems"
                placement="right"
                PopperProps={{ sx: open ? { display: 'none' } : undefined }}
              >
                <RouteIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Trail Systems" />
          </ListItemButton>
        </ListItem>
      )}
      {UserService.hasPermissions(user, [AppPermissions.viewWeatherGroup]) && (
        <ListItem disablePadding>
          <ListItemButton
            component={AppLink}
            to="/weather-groups"
            selected={location.pathname.startsWith('/weather-groups')}
            onClick={() => (isMobile ? closeDrawer() : undefined)}
          >
            <ListItemIcon>
              <Tooltip
                title="Weather Groups"
                placement="right"
                PopperProps={{ sx: open ? { display: 'none' } : undefined }}
              >
                <CloudIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Weather Groups" />
          </ListItemButton>
        </ListItem>
      )}
      {UserService.hasPermissions(user, [AppPermissions.viewManualTrailStatus]) && (
        <ListItem disablePadding>
          <ListItemButton
            component={AppLink}
            to="/manual-trail-statuses"
            selected={location.pathname.startsWith('/manual-trail-statuses')}
            onClick={() => (isMobile ? closeDrawer() : undefined)}
          >
            <ListItemIcon>
              <Tooltip
                title="Manual Statuses"
                placement="right"
                PopperProps={{ sx: open ? { display: 'none' } : undefined }}
              >
                <TrackChangesIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Manual Statuses" />
          </ListItemButton>
        </ListItem>
      )}
      {UserService.hasPermissions(user, [AppPermissions.viewConditionalStatus]) && (
        <ListItem disablePadding>
          <ListItemButton
            component={AppLink}
            to="/conditional-status-groups"
            selected={location.pathname.startsWith('/conditional-status-groups')}
            onClick={() => (isMobile ? closeDrawer() : undefined)}
          >
            <ListItemIcon>
              <Tooltip
                title="Conditional Status Groups"
                placement="right"
                PopperProps={{ sx: open ? { display: 'none' } : undefined }}
              >
                <TuneIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Conditional Status Groups" />
          </ListItemButton>
        </ListItem>
      )}
      {UserService.hasPermissions(user, [AppPermissions.viewSponsor]) && (
        <ListItem disablePadding>
          <ListItemButton
            component={AppLink}
            to="/sponsors"
            selected={location.pathname.startsWith('/sponsors')}
            onClick={() => (isMobile ? closeDrawer() : undefined)}
          >
            <ListItemIcon>
              <Tooltip title="Sponsors" placement="right" PopperProps={{ sx: open ? { display: 'none' } : undefined }}>
                <HandshakeIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Sponsors" />
          </ListItemButton>
        </ListItem>
      )}
      {UserService.hasPermissions(user, [AppPermissions.viewTrailFinder]) && (
        <ListItem disablePadding>
          <ListItemButton
            component={AppLink}
            to="/trail-finder-questions"
            selected={location.pathname.startsWith('/trail-finder-questions')}
            onClick={() => (isMobile ? closeDrawer() : undefined)}
          >
            <ListItemIcon>
              <Tooltip
                title="Trail Finder"
                placement="right"
                PopperProps={{ sx: open ? { display: 'none' } : undefined }}
              >
                <Box>
                  <SignPostIcon />
                </Box>
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Trail Finder" />
          </ListItemButton>
        </ListItem>
      )}
      {UserService.hasPermissions(user, [AppPermissions.viewTrailFeature]) && (
        <>
          <ListItem disablePadding>
            <ListItemButton
              component={AppLink}
              to="/trail-features"
              selected={location.pathname.startsWith('/trail-features')}
              onClick={() => (isMobile ? closeDrawer() : undefined)}
            >
              <ListItemIcon>
                <Tooltip
                  title="Trail Features"
                  placement="right"
                  PopperProps={{ sx: open ? { display: 'none' } : undefined }}
                >
                  <ArtTrackIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Trail Features" />
            </ListItemButton>
          </ListItem>
        </>
      )}
      {UserService.hasPermissions(user, [AppPermissions.editConfig]) && (
        <>
          <Divider sx={{ my: 1 }} />
          <ListItem disablePadding>
            <ListItemButton
              component={AppLink}
              to="/config"
              selected={location.pathname.startsWith('/config')}
              onClick={() => (isMobile ? closeDrawer() : undefined)}
            >
              <ListItemIcon>
                <Tooltip title="Config" placement="right" PopperProps={{ sx: open ? { display: 'none' } : undefined }}>
                  <SettingsIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Config" />
            </ListItemButton>
          </ListItem>
        </>
      )}
      <Divider sx={{ my: 1 }} />
      <ListItem disablePadding>
        <ListItemButton component={AppLink} to="/logout" onClick={() => (isMobile ? closeDrawer() : undefined)}>
          <ListItemIcon>
            <Tooltip title="Logout" placement="right" PopperProps={{ sx: open ? { display: 'none' } : undefined }}>
              <LogoutIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </ListItem>
    </List>
  )
}
export default AppNavigation
