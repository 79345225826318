import { useEffect, useMemo, useState } from 'react'
import * as d3 from 'd3'
import { useAuth0 } from '@auth0/auth0-react'
import pick from 'lodash/pick'
import { formatDate, getObjectKeyFromValue } from '../../../utils/formatting'

import { AppLayoutContext } from '../../../providers/AppLayout'
import { useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { ConditionalStatus, conditionalStatusFields } from '../../../models/ConditionalStatus.model'
import { ConditionalStatusGroup } from '../../../models/ConditionalStatusGroup.model'
import { ConditionalStatusRelations } from '../../../models/ConditionalStatusRelations.model'

import UserService from '../../../services/UserService/UserService'
import { ConditionalStatusService } from '../../../services/ConditionalStatusService/ConditionalStatusService'

import {
  Box,
  MenuItem,
  Stack,
  Typography,
  Button,
  IconButton,
  Paper,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DataTable from '../../../components/DataTable/DataTable.component'
import Loading from '../../../components/Loading/Loading.component'
import ImportExportDialog, {
  ImportExportDialogProps,
} from '../../../components/ImportExportDialog/ImportExportDialog.component'
import Link from '@mui/material/Link'
import ConditionalStatusForm from '../shared/ConditionalStatusForm.component'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AppStateContext } from '../../../providers/AppStateContext'
import { TrailStatuses } from '../../../models/TrailStatuses.model'

type ConditionalStatusSearchProps = {
  nested?: boolean
  conditionalStatusGroup?: ConditionalStatusGroup
  hideHeader?: boolean
  hideActions?: boolean
  disablePadding?: boolean
  excludeColumns?: string[]
  extraRowActions?: {
    label: string
    onClick: (row?: ConditionalStatus) => void
  }[]
}
const ConditionalStatusSearch = (props: ConditionalStatusSearchProps): JSX.Element => {
  const {
    nested,
    conditionalStatusGroup,
    hideHeader = !conditionalStatusGroup,
    hideActions = false,
    disablePadding = false,
    excludeColumns = [],
    extraRowActions = [],
  } = props

  const [isSaving, setIsSaving] = useState(false)

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editTrailFinder])

  const [searchError, setSearchError] = useState('')
  const [rowActionsAnchorEl, setRowActionsAnchorEl] = useState<null | HTMLElement>(null)
  const isActionsMenuOpen = Boolean(rowActionsAnchorEl)
  const [selectedRow, setSelectedRow] = useState<ConditionalStatus | undefined>()
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const [orderBy, setOrderBy] = useState<keyof ConditionalStatus | undefined>('sortWeight')
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc' | undefined>('asc')
  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 1),
  )
  const [pageSize, setPageSize] = useUrlStateParams(
    12,
    'page-size',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 12),
  )

  const [isImportExportOpen, setIsImportExportOpen] = useState(false)

  const conditionalStatusService = useMemo(() => new ConditionalStatusService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    if (!nested) {
      AppLayoutContext.setPageName('Conditional Statuses')
    }
  }, [])

  const queryClient = useQueryClient()
  const { isLoading, data: conditionalStatusesResponse } = useQuery(
    [
      'ConditionalStatusService.search',
      {
        conditionalStatusGroupId: conditionalStatusGroup?.id,
        page,
        pageSize,
        orderBy,
        orderDirection,
        relations: [],
      },
      AppStateContext.getRegion(),
    ],
    () =>
      conditionalStatusService.search({
        conditionalStatusGroupId: conditionalStatusGroup?.id,
        page,
        pageSize,
        orderBy,
        orderDirection,
        relations: [],
      }),
    {
      onError: (err) => {
        setSearchError(typeof err === 'string' ? err : 'An error occurred while searching for conditional statuses.')
        console.error(err)
      },
    },
  )

  useEffect(() => {
    if (
      conditionalStatusesResponse &&
      page > 1 &&
      (!conditionalStatusesResponse.total || (page - 1) * pageSize >= conditionalStatusesResponse.total)
    ) {
      // page out of range was requested so reset to page 1
      setPage(1)
    }
  }, [conditionalStatusesResponse])

  const handleRowActionsClick = (event: React.MouseEvent<HTMLButtonElement>, conditionalStatus: ConditionalStatus) => {
    setRowActionsAnchorEl(event.currentTarget)
    setSelectedRow(conditionalStatus)
  }
  const handleRowActionsClose = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
  }

  const columns = useMemo(() => {
    const columns: GridColDef<ConditionalStatus>[] = [
      {
        field: 'actions',
        headerName: '',
        width: 60,
        sortable: false,
        renderCell: (params) => (
          <>
            <IconButton
              id={`row-actions-button-${params.row.id}`}
              color="primary"
              aria-label="row actions"
              aria-controls={selectedRow?.id === params.row.id ? 'row-actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={selectedRow?.id === params.row.id ? 'true' : undefined}
              onClick={(event) => handleRowActionsClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        renderCell: (params) => (
          <Link color="primary" aria-label="conditional status detail" href={`/conditional-statuses/${params.row.id}`}>
            {params.row.id}
          </Link>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 400,
        renderCell: (params) =>
          getObjectKeyFromValue(TrailStatuses, params.row.status as TrailStatuses) || params.row.status,
      },
      {
        field: 'precipMin',
        headerName: 'Precip Min',
        width: 100,
      },
      {
        field: 'precipMax',
        headerName: 'Precip Max',
        width: 100,
      },
      {
        field: 'lookbackHoursMin',
        headerName: 'Lookback Hrs Min',
        width: 100,
      },
      {
        field: 'lookbackHoursMax',
        headerName: 'Lookback Hrs Max',
        width: 100,
      },
      {
        field: 'sortWeight',
        headerName: 'Sort',
        width: 100,
      },
      {
        field: 'updatedAt',
        headerName: 'Updated',
        width: 200,
        renderCell: (params) => formatDate(params.row.updatedAt),
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        width: 200,
        renderCell: (params) => formatDate(params.row.createdAt),
      },
    ]
    // remove any columns specified to be removed in the excludeColumns prop
    if (excludeColumns) {
      return columns.filter((column) => !excludeColumns.includes(column.field as string))
    }
    return columns
  }, [excludeColumns, hasWritePermissions])

  const handleAddClick = () => {
    setIsAdding(true)
  }

  const handleAddCancel = () => {
    setIsAdding(false)
  }

  const handleAddSubmit = () => {
    setIsAdding(false)
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleEditCancel = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleEditSubmit = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false)
    handleRowActionsClose()
  }

  const deleteConditionalStatus = useMutation(
    (mutationParams: { id: number }) => {
      setIsSaving(true)
      return conditionalStatusService.delete(mutationParams.id)
    },
    {
      onSuccess: () => {
        SnackbarContext.show('Conditional Status deleted successfully!')
        setRowActionsAnchorEl(null)
        setSelectedRow(undefined)
        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSaving(false)
      },
      onError: (err) => {
        SnackbarContext.show(`Conditional Status failed to delete: ${err}`, 'error')
        console.error(err)
        setIsSaving(false)
      },
    },
  )

  const handleDelete = () => {
    if (selectedRow?.id) {
      setIsDeleteConfirmationOpen(false)
      deleteConditionalStatus.mutate({ id: selectedRow.id })
    }
  }

  const openImportExport = () => {
    setIsImportExportOpen(true)
  }

  const handleImportExportClose = () => {
    setIsImportExportOpen(false)
  }

  const handleImport: ImportExportDialogProps['onImport'] = (data: Partial<ConditionalStatus>[], options) => {
    setIsImportExportOpen(false)
    if (!data) {
      SnackbarContext.show('Failed to read/parse file', 'error')
      console.error('Failed to read/parse file')
    } else {
      // enforce the conditionalStatusGroupId if one is available
      if (conditionalStatusGroup?.id) {
        data = data.map((conditionalStatus) => ({
          ...conditionalStatus,
          conditionalStatusGroupId: conditionalStatusGroup.id,
        }))
      }
      setIsSaving(true)
      conditionalStatusService
        .import(data, options)
        .then(() => {
          SnackbarContext.show('Conditional Statuses imported successfully!')

          // fetch new data
          setSearchError('')
          // invalidate all queries to ensure any queries that are using the modified record are updated
          queryClient.invalidateQueries()
          setIsSaving(false)
        })
        .catch((err) => {
          SnackbarContext.show(`Conditional Statuses failed to import: ${err}`, 'error')
          console.error(err)
          setIsSaving(false)
        })
    }
  }

  const handleExport = () => {
    // do a request to the API to get the data to export with no pagination
    setIsSaving(true)
    setIsImportExportOpen(false)
    conditionalStatusService
      .search({
        conditionalStatusGroupId: conditionalStatusGroup?.id,
        pageSize: 0,
        orderBy,
        orderDirection,
        relations: [ConditionalStatusRelations.conditionalStatusGroup],
      })
      .then((results) => {
        const exportData = results.data.map((conditionalStatus) => {
          const conditionalStatusToExport: Record<string, unknown> = { ...conditionalStatus }
          conditionalStatusToExport.conditionalStatusGroupId = conditionalStatus.conditionalStatusGroup?.id
          delete conditionalStatusToExport.conditionalStatusGroup

          conditionalStatusToExport.updatedAt =
            conditionalStatusToExport.updatedAt instanceof Date ? conditionalStatusToExport.updatedAt.toISOString() : ''
          conditionalStatusToExport.createdAt =
            conditionalStatusToExport.createdAt instanceof Date ? conditionalStatusToExport.createdAt.toISOString() : ''

          // return the object with the props in the order we want them to appear in the csv
          const propsToExport: string[] = [...conditionalStatusFields]
          return pick(conditionalStatusToExport, propsToExport)
        })
        const csvData = d3.csvFormat(exportData)
        // initiate a download of the csv file
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.setAttribute('href', url)
        link.setAttribute('download', 'conditional-statuses.csv')
        link.click()
        setIsSaving(false)
      })
      .catch((err) => {
        SnackbarContext.show(`Failed to fetch conditional statuses for export: ${err}`, 'error')
        console.error(err)
      })
  }

  return (
    <>
      {selectedRow && (
        <FormDialog<ConditionalStatus>
          open={isEditing}
          entity={selectedRow}
          entityLabel="Conditional Status"
          onClose={handleEditCancel}
        >
          <ConditionalStatusForm
            conditionalStatus={selectedRow}
            conditionalStatusGroup={conditionalStatusGroup}
            onEdit={handleEditSubmit}
            onCancel={handleEditCancel}
          />
        </FormDialog>
      )}
      <FormDialog<ConditionalStatus> open={isAdding} entityLabel="Conditional Status" onClose={handleAddCancel}>
        <ConditionalStatusForm
          conditionalStatusGroup={conditionalStatusGroup}
          onAdd={handleAddSubmit}
          onCancel={handleAddCancel}
        />
      </FormDialog>
      <Dialog fullWidth maxWidth="xs" open={isDeleteConfirmationOpen} onClose={handleDeleteConfirmationClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete conditional status id {selectedRow?.id}?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteConfirmationClose}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <ImportExportDialog
        open={isImportExportOpen}
        disableImport={!hasWritePermissions}
        exampleImportFileUrl="/example-import-files/conditional-statuses.csv"
        importFields={[...conditionalStatusFields.filter((field) => !['updatedAt', 'createdAt'].includes(field))]}
        onImport={handleImport}
        onExport={handleExport}
        onClose={handleImportExportClose}
      />
      <Paper sx={{ p: disablePadding ? 0 : 2 }}>
        {!hideHeader && (
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            Conditional Statuses
          </Typography>
        )}
        {(isLoading || isSaving) && !searchError && <Loading sx={{ py: 20 }} />}
        {searchError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{searchError}</Typography>
          </Stack>
        )}
        {!isLoading && !isSaving && !searchError && (
          <>
            {!hideActions && (
              <Box sx={{ pt: 1, mb: 1 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {hasWritePermissions && (
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        color="primary"
                        aria-label="add new"
                        onClick={handleAddClick}
                      >
                        Add
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      startIcon={<ImportExportIcon />}
                      color="primary"
                      aria-label="show import export"
                      onClick={() => {
                        openImportExport()
                      }}
                    >
                      Import / Export
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            )}

            {conditionalStatusesResponse?.data && conditionalStatusesResponse.data.length > 0 ? (
              <>
                <Menu
                  id="row-actions-menu"
                  anchorEl={rowActionsAnchorEl}
                  open={isActionsMenuOpen}
                  onClose={handleRowActionsClose}
                  MenuListProps={{
                    'aria-labelledby': `row-actions-button${selectedRow?.id}`,
                  }}
                >
                  <MenuItem href={`/conditional-statuses/${selectedRow?.id}`} component={Link}>
                    View
                  </MenuItem>
                  {!hideActions && hasWritePermissions && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}
                  {!hideActions && hasWritePermissions && <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>}
                  {Array.isArray(extraRowActions) && extraRowActions.length > 0 && <Divider sx={{ my: 0.5 }} />}
                  {Array.isArray(extraRowActions) &&
                    extraRowActions.length > 0 &&
                    extraRowActions.map((action, index) => (
                      <MenuItem key={index} onClick={() => action.onClick(selectedRow)}>
                        {action.label}
                      </MenuItem>
                    ))}
                </Menu>
                <DataTable
                  columns={columns}
                  data={conditionalStatusesResponse.data}
                  total={conditionalStatusesResponse.total}
                  paginationMode="server"
                  page={page}
                  pageSize={pageSize}
                  onPageChange={(newPage) => {
                    setPage(newPage)
                  }}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  onOrderChange={(newOrder) => {
                    setOrderBy(newOrder.field ? (newOrder.field as keyof ConditionalStatus) : undefined)
                    setOrderDirection(newOrder.sort)
                  }}
                />
              </>
            ) : (
              <Box sx={{ p: 2 }}>
                <Typography>No results found</Typography>
              </Box>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default ConditionalStatusSearch
